import React from 'react'
import classNames from 'classnames'
import css from './index.module.scss'
import { Button, Space } from 'antd'
import {
  CloseOutlined,
  FileOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined
} from '@ant-design/icons'
import type { FC, ReactNode } from 'react'

type CommonButtonProps = {
  onClick?: () => void
  children?: ReactNode
  disabled?: boolean
  hidden?: boolean
}

interface Props {
  createButtonProps?: CommonButtonProps
  deleteButtonProps?: CommonButtonProps
  updateButtonProps?: CommonButtonProps
  uploadButtonProps?: CommonButtonProps & { loading?: boolean }
  downloadButtonProps?: CommonButtonProps & { loading?: boolean }
  children?: ReactNode
}

const isVisible = (actionProps: Props[keyof Omit<Props, 'children'>]) =>
  !actionProps?.hidden &&
  actionProps?.children &&
  typeof actionProps.onClick === 'function'

const getIcon = (propType: keyof Props) => {
  switch (propType) {
    case 'createButtonProps':
      return <PlusOutlined />
    case 'deleteButtonProps':
      return <CloseOutlined />
    case 'uploadButtonProps':
      return <VerticalAlignBottomOutlined />
    case 'downloadButtonProps':
      return <FileOutlined />
    default:
      return null
  }
}

export const CrudPanel: FC<Props> = ({
  createButtonProps,
  deleteButtonProps,
  updateButtonProps,
  uploadButtonProps,
  downloadButtonProps,
  children
}) => (
  <Space className={classNames([css.rowReverse, css.fullWith])}>
    {children}
    {Object.entries({
      createButtonProps,
      deleteButtonProps,
      updateButtonProps,
      uploadButtonProps,
      downloadButtonProps
    }).map(
      ([propType, props], ind) =>
        isVisible(props) && (
          <Button
            key={ind}
            className={css.fitContent}
            icon={getIcon(propType as keyof Props)}
            {...props}
            type="primary"
          />
        )
    )}
  </Space>
)
