import React from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'
import type { ModalFuncProps } from 'antd'
import type { ReactNode } from 'react'

const { confirm } = Modal

interface Props {
  title: ReactNode
  content: ReactNode
  onOk?: () => void
  onCancel?: () => void
  getContainer?: () => HTMLElement
  onOkAsyncCb?: (args?: any) => Promise<unknown>
  modalRender?: ModalFuncProps['modalRender']
}

const getBody = () => document.body

export const showConfirm = ({
  title,
  content,
  onOk,
  onCancel,
  getContainer,
  onOkAsyncCb,
  modalRender
}: Props) => {
  confirm({
    title,
    icon: <ExclamationCircleFilled />,
    content,
    okText: 'Продолжить',
    cancelText: 'Отмена',
    modalRender,
    onOk() {
      if (typeof onOk === 'function') {
        onOk()
      }
      if (typeof onOkAsyncCb === 'function') {
        return onOkAsyncCb()
      }
    },
    onCancel() {
      if (typeof onCancel === 'function') {
        onCancel()
      }
    },
    getContainer:
      typeof getContainer === 'function' ? getContainer() : getBody()
  })
}
