import { formatValidationErrors } from 'io-ts-reporters'
import { isRight } from 'fp-ts/Either'
import { useCallback } from 'react'
import { useErrorNotificationShow } from '../../store/notifications'
// eslint-disable-next-line import/no-duplicates
import type * as t from 'io-ts'
// eslint-disable-next-line import/no-duplicates
import type { Mixed } from 'io-ts'

export const useCheckValuesWithErrorNotification = () => {
  const showErrorNotification = useErrorNotificationShow()
  return useCallback(<C extends Mixed>(codec: C, values: t.InputOf<C>) => {
    const either = codec.decode(values)
    if (isRight(either)) {
      return either.right
    } else {
      const err: string[] = formatValidationErrors(either.left)
      const [firstErr] = err

      showErrorNotification(firstErr)
      throw new Error(firstErr)
    }
  }, [])
}
