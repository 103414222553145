import dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { pick } from 'ramda'
import { useCallback } from 'react'
import type { LoginUser } from '../../../api/types/loginData'
import type { MaybeNull } from '../../atoms/ts/generics'

export enum Screens2FA {
  REQUEST_OTP = 0,
  EMAIL_SENT = 1,
  LOGIN = 2,
  RENEW = 3
}

type UserData = {
  token: string
  user: LoginUser
}

type UserDataTokenOnly = Pick<UserData, 'token'>

type UserDataOtp = {
  email: string
  otpToken: string
  otpExpiresAt: MaybeNull<string>
}

interface UserStore {
  jwtToken: string
  email: string
  otpToken: string
  otpExpiresAt: MaybeNull<string>
  userInfo: MaybeNull<LoginUser>
  isLoading: boolean
  screen: Screens2FA
  forceRefresh: boolean
  setUserInfo: (data: UserDataArgs) => void
  setJwtTokenInfo: (data: UserDataTokenOnly) => void
  setOtpTokenInfo: (data: UserDataOtp) => void
  setUserLoading: (bool: boolean) => void
  setScreen: (screen: Screens2FA) => void
  setForceRefresh: (bool: boolean) => void
  reset: () => void
}

type UserDataArgs = MaybeNull<UserData>

const initialUserStore = {
  jwtToken: '',
  otpToken: '',
  email: '',
  otpExpiresAt: null,
  userInfo: null,
  isLoading: false,
  screen: Screens2FA.REQUEST_OTP,
  forceRefresh: false
}

const initialOtpState = pick(
  ['otpToken', 'email', 'otpExpiresAt'],
  initialUserStore
)

const persistOptions = {
  name: 'userStore',
  storage: createJSONStorage(() => localStorage)
} as const

export const useUserStore = create<UserStore>()(
  persist(
    (set, get) => ({
      ...initialUserStore,
      setUserLoading: bool => {
        set({
          ...get(),
          isLoading: bool
        })
      },
      setUserInfo: (data: UserDataArgs) => {
        set({
          jwtToken: data?.token ?? '',
          userInfo: data?.user ?? null
        })
      },
      setJwtTokenInfo: (data: UserDataTokenOnly) => {
        set({
          ...get(),
          jwtToken: data.token
        })
      },
      setOtpTokenInfo: (data: UserDataOtp) => {
        set({
          ...get(),
          ...data
        })
      },
      setScreen: (screen: Screens2FA) => {
        set({
          ...get(),
          screen
        })
      },
      setForceRefresh: (bool: boolean) => {
        set({
          ...get(),
          forceRefresh: bool
        })
      },
      reset: () => {
        set(initialUserStore)
      }
    }),
    persistOptions
  )
)

export const useSetUserInfoStore = () =>
  useUserStore(store => store.setUserInfo)

export const useSetJwtTokenInfoStore = () =>
  useUserStore(store => store.setJwtTokenInfo)

export const useUserTokenStore = () => useUserStore(store => store.jwtToken)

export const useUserScreenStore = () => useUserStore(store => store.screen)

export const useUserSetScreenStore = () =>
  useUserStore(store => store.setScreen)

export const useUserOtpTokenStore = () =>
  useUserStore(store => ({
    email: store.email,
    otpToken: store.otpToken,
    otpExpiresAt: store.otpExpiresAt
  }))

export const useSetUserOtpTokenInfoStore = () =>
  useUserStore(store => store.setOtpTokenInfo)

export const useUserDeadlineToken = () => {
  const otpInfo = useUserOtpTokenStore()

  if (!otpInfo.otpExpiresAt) {
    return 0
  }

  return Date.now() + dayjs(otpInfo.otpExpiresAt).diff(dayjs(), 'ms', true)
}

export const useUserResetOtpInfo = () => {
  const setOtpTokenInfo = useSetUserOtpTokenInfoStore()

  return useCallback(() => {
    setOtpTokenInfo(initialOtpState)
  }, [])
}

export const useUserInfoStore = () => useUserStore(store => store.userInfo)

export const useUserSetLoadingStore = () =>
  useUserStore(store => store.setUserLoading)
export const useUserLoadingStore = () => useUserStore(store => store.isLoading)

export const useUseForceRefreshStore = () =>
  useUserStore(store => store.forceRefresh)
export const useUserSetForceRefreshStore = () =>
  useUserStore(store => store.setForceRefresh)

export const useUserLogout = () => useUserStore(store => store.reset)
