/* eslint-disable react/jsx-handler-names */
import FormItem from 'antd/es/form/FormItem'
import Input from 'antd/es/input/Input'
import React from 'react'
import css from './index.module.scss'
import { DatePicker, InputNumber, Radio, Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  NotFoundContentWithLoading,
  staticSelectPropsWithFilters
} from '../../atoms/Select'
import { pick } from 'ramda'
import type {
  AsyncSelectManyField,
  AsyncSelectSearchField,
  DatesSearchField,
  MonthYearDateField,
  NumberSearchField,
  SearchField,
  SelectSearchField,
  TextSearchField
} from '.'
import type { FC } from 'react'

const { RangePicker } = DatePicker

interface Props {
  field: SearchField
}

const getTooltipForLabelProps = (tooltip: SearchField['tooltip']) =>
  pick([tooltip ? 'tooltip' : ''], {
    tooltip: {
      title: tooltip,
      icon: <InfoCircleOutlined />
    }
  })

const getNumberWithPrefixProps = (prefix: string | undefined) =>
  pick([prefix ? 'prefix' : ''], {
    prefix
  })

function getFieldCommomProps<T>(field: SearchField) {
  return field.type !== 'radioGroup'
    ? ({
        className: css.fullWidth,
        placeholder: field.placeholder,
        disabled: field.disabled
      } as T)
    : {}
}

function getSelectFieldCommomProps(
  field: SelectSearchField | AsyncSelectSearchField | AsyncSelectManyField
) {
  return {
    loading: field.loading,
    options: field.options,
    ...staticSelectPropsWithFilters
  }
}

const getAsyncSelectProps = (
  field: AsyncSelectSearchField | AsyncSelectManyField
) => ({
  onClear: field.onClear,
  onSearch: field.onSearch,
  allowClear: !field.loading,
  defaultActiveFirstOption: false,
  filterOption: false
})
// eslint-disable-next-line max-lines-per-function
const fieldCases = (field: SearchField) => {
  switch (field.type) {
    case 'searchSelect':
      return (
        <Select
          {...getSelectFieldCommomProps(field)}
          {...getFieldCommomProps<AsyncSelectSearchField>(field)}
          notFoundContent={
            <NotFoundContentWithLoading loading={field.loading} />
          }
          {...getAsyncSelectProps(field)}
        />
      )
    case 'select':
      return (
        <Select
          {...getSelectFieldCommomProps(field)}
          {...getFieldCommomProps<SelectSearchField>(field)}
        />
      )
    case 'dates':
      return (
        <RangePicker
          {...getFieldCommomProps<DatesSearchField>(field)}
          format="DD.MM.YYYY"
        />
      )
    case 'date':
      return (
        <DatePicker
          {...getFieldCommomProps<MonthYearDateField>(field)}
          format="MMMM YYYY"
          picker="month"
        />
      )
    case 'number':
      return (
        <InputNumber
          {...getFieldCommomProps<NumberSearchField>(field)}
          max={field.max}
          min={0}
          step={field.step}
          {...getNumberWithPrefixProps(field.prefix)}
        />
      )
    case 'radioGroup':
      return (
        <Radio.Group>
          {field.options.map(({ label, value }, index) => (
            <Radio.Button key={index} value={value}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      )
    default:
      return <Input {...getFieldCommomProps<TextSearchField>(field)} />
  }
}

export const SearchFormItem: FC<Props> = ({ field }) => (
  <FormItem
    key={field.name}
    help={field.help}
    label={field.label}
    name={field.name}
    required={field.required}
    rules={[{ required: field.required }]}
    {...getTooltipForLabelProps(field.tooltip)}
  >
    {fieldCases(field)}
  </FormItem>
)
