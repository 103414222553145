import { formatDisplayBoolean } from '../../lib/formatters'
import { parseBooleanEitherString } from '../../lib/parsers'
import { renderWithRequiredStar } from '../../atoms/WithRequiredStar'
import type { ColumnsType } from 'antd/es/table'
import type { MaybeUndefined } from '../../atoms/ts/generics'

const renderByBooleanNumOrString = (value?: string) => {
  const isParsed: MaybeUndefined<boolean> = parseBooleanEitherString(value)

  return typeof isParsed === 'boolean'
    ? formatDisplayBoolean(isParsed)
    : undefined
}

export type PsychologistCSV = {
  _id: string
  enableUrgentSessions: boolean
  minHoursBeforeUrgentSession: number
}

export const staticColumns: ColumnsType<PsychologistCSV> = [
  {
    title: 'Индекс',
    dataIndex: 'key',
    fixed: 'left',
    width: 100,
    render: (value: number) => value
  },

  {
    title: 'ID',
    dataIndex: 'id',
    fixed: 'left',
    width: 100,
    render: (value: number) => value
  },
  {
    title: renderWithRequiredStar('Срочные сессии'),
    dataIndex: 'enableUrgentSessions',
    fixed: 'left',
    width: 180,
    render: renderByBooleanNumOrString
  },
  {
    title: renderWithRequiredStar('Часов до срочных сессий'),
    dataIndex: 'minHoursBeforeUrgentSession'
  }
]
