import React from 'react'
import { ConfigProvider } from 'antd'
import type { FC, PropsWithChildren } from 'react'

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => (
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'var(--font)'
      }
    }}
  >
    {children}
  </ConfigProvider>
)

export default ThemeProvider
