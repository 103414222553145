import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type dayjs from 'dayjs'

export type Filter =
  | Record<
      string,
      | string
      | number
      | dayjs.Dayjs
      | [dayjs.Dayjs, dayjs.Dayjs]
      | undefined
      | null
    >
  | undefined

interface Store {
  tables: { [key: string]: Filter }
  setFilters: (tableName: string, filter: Filter) => void
}

export const useTableFiltersStore = create<Store>()(
  persist(
    (set, get) => ({
      tables: {},
      setFilters: (tableName: string, filter: Filter) => {
        set({
          ...get(),
          tables: {
            ...get().tables,
            [tableName]: filter
          }
        })
      }
    }),
    {
      name: 'searchStore'
    }
  )
)

export const useTableFiltersByName = <T,>(tableName: string): T | undefined => {
  const { tables } = useTableFiltersStore()

  return tables[tableName] as T | undefined
}
