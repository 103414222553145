import React from 'react'
import css from './index.module.scss'
import { Modal as AntdModal } from 'antd'
import type { FC } from 'react'
import type { ModalProps } from 'antd'

export const Modal: FC<ModalProps> = props => (
  <AntdModal className={css.modal} {...props} open>
    {props.children}
  </AntdModal>
)
