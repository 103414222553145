import FormItem from 'antd/es/form/FormItem'
import React from 'react'
import {
  NotFoundContentWithLoading,
  getOptionsClient,
  getOptionsCompany,
  staticSelectPropsWithFilters
} from '../../atoms/Select'
import { Select } from 'antd'
import { useCompaniesQuery } from '../../../api/companies'
import { useOnSearchClients } from '../../../api/clients'
import { useWatch } from 'antd/es/form/Form'
import type { FC } from 'react'
import type { FormInstance } from 'antd/es/form/Form'
import type { FormValues } from './index.types'

type Props = {
  form: FormInstance<FormValues>
}

export const UserField: FC<Props> = ({ form }) => {
  const user = useWatch<FormValues>('user', form)
  const { data, isLoading, onSearch, onClear } = useOnSearchClients(
    typeof user === 'string'
      ? {
          initialClientEq: user
        }
      : {}
  )

  return (
    <FormItem required label="Клиент" name="userId">
      <Select
        {...staticSelectPropsWithFilters}
        allowClear={!isLoading}
        defaultActiveFirstOption={false}
        filterOption={false}
        loading={isLoading}
        notFoundContent={<NotFoundContentWithLoading loading={isLoading} />}
        onClear={onClear}
        onSearch={onSearch}
        options={getOptionsClient(data ?? [])}
        placeholder="Введите полный номер клиента для поиска"
      />
    </FormItem>
  )
}

export const CompanyField = () => {
  const { data: companies, isLoading } = useCompaniesQuery({
    limit: 200,
    skip: 0
  })

  return (
    <FormItem required label="Компания" name="companyId">
      <Select
        {...staticSelectPropsWithFilters}
        loading={isLoading}
        options={getOptionsCompany(companies?.data ?? [])}
      />
    </FormItem>
  )
}
