import { equals } from 'ramda'
import { useEffect, useState } from 'react'
import { useUserTokenStore } from '../app/pages/Login/store'
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import type { ValueOf } from '../app/atoms/ts'

export type PaginationParams = {
  skip?: number
  limit?: number
  sort?: string
}

export const refetchOnlyParams = {
  enabled: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: Number.POSITIVE_INFINITY,
  cacheTime: Number.POSITIVE_INFINITY
}

export const nonNullishParams = <T extends Record<string, any>>(params: T) => {
  const result: Record<
    string,
    Exclude<ValueOf<T>, '' | undefined | null>
  > = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) =>
        value !== '' && !equals(value, null) && typeof value !== 'undefined'
    )
  )

  return result as T
}

export const useCreateRequestInterceptorsWithToken = (
  axiosInstance: AxiosInstance
) => {
  const token = useUserTokenStore()

  const [hasToken, setHasToken] = useState(false)
  function onFullfilled(config: InternalAxiosRequestConfig<any>) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  }

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.request.use(
      onFullfilled,
      null,
      { synchronous: true }
    )

    setHasToken(true)
    return () => {
      axiosInstance.interceptors.request.eject(interceptor)
    }
  }, [token])
  return hasToken
}
