import axios from 'axios'
import { useCreateRequestInterceptorsWithToken } from './utils'

const { REACT_APP_API_OVERRIDE_URL, REACT_APP_URL } = process.env

export const baseURL =
  REACT_APP_API_OVERRIDE_URL ?? `${REACT_APP_URL}/backend/api/crm`

export const apiClient = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json'
  }
})

export const useRequestInterceptorsWithToken = () =>
  useCreateRequestInterceptorsWithToken(apiClient)
