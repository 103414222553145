import { renderWithRequiredStar } from '../../atoms/WithRequiredStar'
import type { ColumnsType } from 'antd/es/table'

export type PsychologistCSV = {
  bitrix_id: string
  ratio: number
}

export const staticColumns: ColumnsType<PsychologistCSV> = [
  {
    title: 'Индекс',
    dataIndex: 'key',
    fixed: 'left',
    width: 100,
    render: (value: number) => value
  },

  {
    title: renderWithRequiredStar('ID'),
    dataIndex: 'bitrixId',
    fixed: 'left',
    width: 100,
    render: (value: number) => value
  },
  {
    title: renderWithRequiredStar('Комиссия'),
    dataIndex: 'ratio',
    fixed: 'left',
    width: 180
  }
]
