import { Buffer } from 'buffer'
import { PROMOCODES } from './promocodes'
import { apiClient } from '.'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { Import } from './types/import'
import type { ImportState, ImportType } from './types/enums'
import type { PaginationParams } from './utils'
import type { UseQueryOptions } from '@tanstack/react-query'

export const IMPORTS = 'IMPORTS'

export type ImprotsGetParams = {
  createdByEq?: string
  idEq?: string
  stateEq?: ImportState
  typeEq?: ImportType
} & PaginationParams

type ImportResponse = {
  importId: string
  count: number
  stats: Import['meta']['stats']
}

type GetImportResult = {
  data: Import[]
  total: number
}

const imports = {
  get: (params: ImprotsGetParams) =>
    apiClient
      .get('/imports', { params })
      .then<GetImportResult>(res => res.data),
  uploadPromo: (stringCSV: string) =>
    apiClient
      .post<ImportResponse>('/promocodes/import', Buffer.from(stringCSV), {
        headers: { 'content-type': 'application/csv' }
      })
      .then(res => res.data)
}

const useImportsQuery = (
  params: ImprotsGetParams,
  options?: UseQueryOptions<GetImportResult>
) =>
  useQuery<GetImportResult>(
    [IMPORTS, 'get'],
    () => imports.get(params),
    options
  )

export const useImportsQueryRefetchOnly = (params: ImprotsGetParams) =>
  useImportsQuery(params, refetchOnlyParams)

export const useImportPromocodesQuery = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [IMPORTS, PROMOCODES],
    (stringCSV: string) => imports.uploadPromo(stringCSV),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([IMPORTS, PROMOCODES])
      }
    }
  )
}
