import React, { useCallback, useEffect } from 'react'
import classNames from 'classnames'
import css from './index.module.scss'
import { Form, Steps } from 'antd'
import { LetterScreen } from '../../organisms/Login/LetterScreen'
import { Loader } from '../../atoms/Loader'
import { LoginForm } from '../../organisms/Login/LoginForm'
import { MailOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { OTPForm } from '../../organisms/Login/OTPForm'
import {
  Screens2FA,
  useUserDeadlineToken,
  useUserLoadingStore,
  useUserLogout,
  useUserScreenStore
} from './store'
import { useForm } from 'antd/es/form/Form'
import { useLoginOnFinish } from './useLoginOnFinish'
import { useOtpRequestOnFinish } from './useOtpRequestOnFinish'
import type { FormInstance } from 'antd/es/form/Form'

const LoginSteps = () => {
  const screen = useUserScreenStore()

  return (
    <Steps
      className={css.fixedTop}
      current={screen}
      items={[
        {
          title: 'Аутентификация OTP',
          icon: <UserOutlined />,
          status: 'finish'
        },
        {
          title: 'Письмо',
          icon: <MailOutlined />
        },
        {
          title: 'Валидация OTP',
          icon: <SolutionOutlined />,
          disabled: true
        }
      ]}
      size="small"
      type="navigation"
    />
  )
}

export type LoginFormValues = {
  email: string
  password: string
  otpCode: number
}

const useAutoLogot = () => {
  const screen = useUserScreenStore()
  const deadline = useUserDeadlineToken()
  const logout = useUserLogout()
  useEffect(() => {
    if (screen === Screens2FA.RENEW) {
      return
    }
    if (Date.now() > deadline) {
      logout()
    }
  }, [screen])
}

const useOnFinish = (setFields: FormInstance<LoginFormValues>['setFields']) => {
  const screen = useUserScreenStore()
  const otpRequestOnFinish = useOtpRequestOnFinish(setFields)
  const loginOnFinish = useLoginOnFinish(setFields)

  return useCallback(
    (values: LoginFormValues) => {
      if (screen === Screens2FA.REQUEST_OTP) {
        otpRequestOnFinish(values)
      }
      if (screen === Screens2FA.LOGIN) {
        loginOnFinish(values)
      }
    },
    [screen]
  )
}

export const Login = () => {
  const [form] = useForm<LoginFormValues>()
  const isLoading = useUserLoadingStore()
  const screen = useUserScreenStore()
  const onFinish = useOnFinish(form.setFields)
  useAutoLogot()

  return (
    <main className={classNames([css.page, css.flexCenter])}>
      {screen !== Screens2FA.RENEW && <LoginSteps />}
      <Form
        className={classNames(css.loginForm, {
          [css.loginFormLoading]: isLoading
        })}
        disabled={isLoading}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        {screen === Screens2FA.REQUEST_OTP && <OTPForm />}
        {screen === Screens2FA.EMAIL_SENT && <LetterScreen />}
        {screen === Screens2FA.LOGIN && (
          <LoginForm resetFields={form.resetFields} />
        )}
        {screen === Screens2FA.RENEW && <>{isLoading && <Loader />}</>}
      </Form>
    </main>
  )
}
