import { Currency } from '../../../api/types/enums'

export enum CurrencySigns {
  RUB = '₽',
  EUR = '€'
}

export const transactionsCurrencSignyMap = (currency: Currency) => {
  switch (currency) {
    case Currency.RUB:
      return CurrencySigns.RUB
    case Currency.EUR:
      return CurrencySigns.EUR
  }
}
