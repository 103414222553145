import { IMPORTS } from './imports'
import { apiClient } from '.'
import { badgeUpdate, badgesGet } from './generated/badges'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  BadgeUpdateBody,
  BadgesGet200DataItem,
  BadgesGetParams
} from './generated/index.schemas'
import type { BadgesGetResult } from './generated/badges'
import type { UseQueryOptions } from '@tanstack/react-query'

const BADGES = 'BADGES'

export type Badge = BadgesGet200DataItem

export const useBadgesQuery = (
  params: BadgesGetParams,
  options?: UseQueryOptions<BadgesGetResult>
) =>
  useQuery<BadgesGetResult>([BADGES, 'get'], () => badgesGet(params), options)

type BadgeUpdateInput = BadgeUpdateBody & { id: string }

export const useBadgeUpdateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [BADGES, 'patch'],
    ({ id, ...input }: BadgeUpdateInput) => badgeUpdate(id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BADGES, 'get'])
      }
    }
  )
}

type BadgesUploadResult = { importId: string }

export const useBadgesUploadQuery = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [IMPORTS, BADGES],
    (stringCSV: string) =>
      apiClient
        .post<BadgesUploadResult>('/badges/upload', stringCSV, {
          headers: { 'content-type': 'text/csv' }
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([IMPORTS, BADGES])
      }
    }
  )
}
