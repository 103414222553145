import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.locale('ru')
dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
  monthsShort: [
    'Янв',
    'Февр',
    'Март',
    'Апр',
    'Май',
    'Июнь',
    'Июль',
    'Авг',
    'Сент',
    'Окт',
    'Нояб',
    'Дек'
  ]
})
