import dayjs from 'dayjs'

const getTimeStamp = () => dayjs().format('DD-MM-YYYY_HH-mm-ss')
export const getDownloadName = (collection = '') =>
  [collection, getTimeStamp()].join('_')

export const getLinkCSV = (text: string) => {
  const file = new Blob([text], {
    type: 'text/csv'
  })
  return URL.createObjectURL(file)
}
