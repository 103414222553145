import { UserRoleId, useUsersQuery } from './users'
import { useDebounce } from '../app/atoms/useDebounce'
import { useState } from 'react'

interface UseOnSearchClientsInput {
  initialClientEq?: string
}

export const useOnSearchClients = ({
  initialClientEq
}: UseOnSearchClientsInput) => {
  const [leadIdEq, setLeadIdEq] = useState<number | undefined>()
  const debondedLeadIdEq = useDebounce(leadIdEq, 800)

  const { data, isFetching: isUsersLoading } = useUsersQuery(
    {
      roleEq: UserRoleId.CLIENT,
      leadIdEq: debondedLeadIdEq?.toString(),
      ...(initialClientEq && !leadIdEq ? { idEq: initialClientEq } : {})
    },
    {
      enabled: Boolean(debondedLeadIdEq ?? initialClientEq)
    }
  )
  const isValidData = leadIdEq ?? initialClientEq

  return {
    data: isValidData ? data?.data : [],
    isLoading: isUsersLoading,
    onSearch: (value: string) => {
      setLeadIdEq(Number(value))
    },
    onClear: () => {
      setLeadIdEq(undefined)
    }
  }
}
