import { apiClient } from '.'
import type { LoginData, OtpData, TokenRenewData } from './types/loginData'

type CredentialsOtp = {
  email: string
  password: string
}

type CredentialsLogin = {
  email: string
  otpToken: string
  otpCode: number
}

type TokenCredentials = {
  token: string
}

export const auth = {
  requestOtp: (body: CredentialsOtp) =>
    apiClient.post<OtpData>('/auth/request', body),
  login: (body: CredentialsLogin) =>
    apiClient.post<LoginData>('/auth/login', body),
  renewToken: (body: TokenCredentials) =>
    apiClient.post<TokenRenewData>('/auth/renew', body)
}

export enum Auth2FAErrorsCode {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_OTP_CODE = 'INVALID_OTP_CODE'
}

type Error = {
  data?: {
    message: string
    description: string
    statusCode: string | number
    code: Auth2FAErrorsCode
  }
}

export type AuthErrorResponse = Record<string, any> & {
  response?: Error
}

const newtworkError = 'Ошибка сети, проверьте подключение к серверу'
export const ERR_NETWORK = 'ERR_NETWORK' as const

const errorAuthMap = {
  429: 'Слишком много попыток, попробуйте позже',
  500: newtworkError,
  502: newtworkError
} as const

export const parseServerError = (error: AuthErrorResponse) => {
  const serverErrorCode =
    error.code === ERR_NETWORK ? 500 : error.response?.data?.statusCode

  if (error.response?.data?.message) {
    return error.response.data.message
  }

  if (!serverErrorCode) {
    return ''
  }

  return errorAuthMap[serverErrorCode as keyof typeof errorAuthMap]
}
