import Axios from 'axios'
import { baseURL } from '.'
import { useCreateRequestInterceptorsWithToken } from './utils'
import type { AxiosRequestConfig } from 'axios'

export const AXIOS_INSTANCE = Axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json'
  }
})

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token
  }).then(({ data }) => data)

  // @ts-expect-error
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export const useRequestInterceptorsWithToken2 = () =>
  useCreateRequestInterceptorsWithToken(AXIOS_INSTANCE)
