import FormItem from 'antd/es/form/FormItem'
import React, { useEffect, useState } from 'react'
import css from './index.module.scss'
import { Button, Input, Row, Statistic, Typography } from 'antd'
import { Loader } from '../../../atoms/Loader'
import { LoginOutlined } from '@ant-design/icons'
import {
  useUseForceRefreshStore,
  useUserDeadlineToken,
  useUserLoadingStore,
  useUserLogout,
  useUserSetForceRefreshStore
} from '../../../pages/Login/store'
import { useWatch } from 'antd/es/form/Form'
import type { FC } from 'react'
import type { FormInstance } from 'antd/es/form/Form'
import type { LoginFormValues } from '../../../pages/Login'

const { Countdown } = Statistic

type HelpProps = { help: string } | Record<string, never>

const OtpField = () => {
  const [helpProps, setHelpProps] = useState<HelpProps>({
    help: 'Пожалуйста, будьте внимательны, если вы ввели неправильный код один раз. то вам придется запросить временный токен снова'
  })
  const otpCode = useWatch('otpCode')

  useEffect(() => {
    if (otpCode) {
      setHelpProps({})
    }
  }, [otpCode])

  return (
    <FormItem
      label="Код"
      name="otpCode"
      {...helpProps}
      rules={[
        {
          required: true,
          message: 'Пожалуйста, введите четырехзначный код'
        },
        () => ({
          validator(_: any, value: string | undefined) {
            if (Number.isNaN(Number(value))) {
              return Promise.reject(
                new Error('Не является четырехзначным числом')
              )
            }

            return Promise.resolve()
          }
        })
      ]}
    >
      <Input maxLength={4} minLength={4} placeholder="_ _ _ _" size="large" />
    </FormItem>
  )
}

type LoginFormProps = {
  resetFields: FormInstance<LoginFormValues>['resetFields']
}

type TextInfoProps = {
  forceRefresh: boolean
}

const TextContent: FC<TextInfoProps> = ({ forceRefresh }) => {
  const logout = useUserLogout()
  const storeDeadline = useUserDeadlineToken()
  const deadline = forceRefresh ? Date.now() + 5500 : storeDeadline
  return (
    <>
      <Typography.Paragraph className={css.textAlignCenter}>
        {forceRefresh
          ? 'Вы вернетесь на первую страницу авторизации через'
          : 'Срок действия текущего токена истекает через'}{' '}
        <Typography.Paragraph strong>
          <Countdown format="mm:ss" onFinish={logout} value={deadline} />
        </Typography.Paragraph>
      </Typography.Paragraph>
    </>
  )
}

export const LoginForm: FC<LoginFormProps> = ({ resetFields }) => {
  const isLoading = useUserLoadingStore()
  const setForceRefresh = useUserSetForceRefreshStore()
  const isForceRefresh = useUseForceRefreshStore()
  const otpCode = useWatch('otpCode')

  useEffect(
    () => () => {
      if (isForceRefresh) {
        setForceRefresh(false)
      }
      resetFields()
    },
    []
  )

  return (
    <div>
      {isLoading && <Loader />}
      <TextContent forceRefresh={isForceRefresh} />
      <OtpField />
      <Row align="bottom" justify="end">
        <Button
          disabled={isForceRefresh || !otpCode || isLoading}
          htmlType="submit"
          icon={<LoginOutlined />}
          size="large"
          type="primary"
        >
          Войти
        </Button>
      </Row>
    </div>
  )
}
