import React, { memo } from 'react'
import css from './index.module.scss'
import { Button, Tooltip } from 'antd'
import type { FC } from 'react'

type Props = {
  link: string
  downloadName: string
}

export const DownloadLink: FC<Props> = memo(({ link, downloadName }) => (
  <Tooltip title="Нажмите на ссылку, чтобы скачать файл">
    <Button
      className={css.actionButton}
      download={downloadName}
      href={link}
      type="link"
    >
      {downloadName}
    </Button>
  </Tooltip>
))
