import { camelCase } from 'change-case'
import { parse } from 'papaparse'

export const csv2Json = <T>(stringCSV: string) =>
  parse<T>(stringCSV, {
    header: true,
    skipEmptyLines: true,
    transformHeader(h) {
      return camelCase(h)
    }
  })

export const map2SelectOptions = (map: Record<string, string>) =>
  Object.entries(map).map(([value, label]) => ({
    label,
    value
  }))
