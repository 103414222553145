import './global.scss'
import App from './app/App'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

const rootElement = document.querySelector('#root') as HTMLElement

if (process.env.NODE_ENV === 'development') {
  ReactDOM.render(<App />, rootElement)
}
if (process.env.NODE_ENV === 'production') {
  const root = createRoot(rootElement)
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}
