/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * YouTalk CRM API
 * OpenAPI spec version: 1.0.0
 */
import type {
  PsychologistsGet200,
  PsychologistsGetParams,
  PsychologistsSearchGet200,
  PsychologistsSearchGetParams
} from './index.schemas'
import { customInstance } from '../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * @summary Retrieve psychologists
 */
export const psychologistsGet = (
    params?: PsychologistsGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PsychologistsGet200>(
      {url: `/psychologists`, method: 'GET',
        params
    },
      options);
    }
  /**
 * @summary Retrieve psychologists
 */
export const psychologistsSearchGet = (
    params: PsychologistsSearchGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PsychologistsSearchGet200>(
      {url: `/psychologists/search`, method: 'GET',
        params
    },
      options);
    }
  export type PsychologistsGetResult = NonNullable<Awaited<ReturnType<typeof psychologistsGet>>>
export type PsychologistsSearchGetResult = NonNullable<Awaited<ReturnType<typeof psychologistsSearchGet>>>
