import React from 'react'
import classNames from 'classnames'
import css from './index.module.scss'
import { Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import type { FC } from 'react'

interface Props {
  onReset: () => void
}

export const ButtonGroup: FC<Props> = ({ onReset }) => (
  <div className={classNames([css.flexRowReverse, css.fullWidth])}>
    <Button onClick={onReset}>Сбросить</Button>
    <Button htmlType="submit" icon={<ReloadOutlined />} type="primary">
      Обновить данные
    </Button>
  </div>
)
