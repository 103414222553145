import * as t from 'io-ts'
import { booleanStringAllTypes, positiveNumber } from '../../lib/io-ts/number'

export const FormDataType = t.array(
  t.interface({
    id: t.string,
    enableUrgentSessions: booleanStringAllTypes,
    minHoursBeforeUrgentSession: positiveNumber
  })
)
