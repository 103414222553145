import { useCallback, useState } from 'react'
import { useSuccessNotificationShow } from '../store/notifications'

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean>

export const useCopyToClipboard = (): [CopiedValue, CopyFn] => {
  const showSuccessNotification = useSuccessNotificationShow()
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const copy: CopyFn = useCallback(async text => {
    await navigator.clipboard.writeText(text)
    setCopiedText(text)
    showSuccessNotification(
      ['Текст:', text, 'успешно скопирован в буфер обмена'].join(' ')
    )
    return true
  }, [])

  return [copiedText, copy]
}
