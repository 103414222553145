import React, { useEffect, useState } from 'react'
import css from './index.module.scss'

export const SpinnerText = ({ text = 'Загрузка' }) => {
  const [dots, setDots] = useState('.')
  useEffect(() => {
    const interfalId = setInterval(() => {
      setDots(prev => {
        if (prev.length > 3) {
          return '.'
        } else {
          return `${prev}.`
        }
      })
    }, 250)

    return () => clearInterval(interfalId)
  }, [])

  return <span className={css.spinnerText}>{[text, dots].join('')}</span>
}
