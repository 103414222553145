import React, { useRef, useState } from 'react'
import { Checkbox } from 'antd'
import { FormDataType } from './io-types'
import { UploadPage } from '../../organisms/Uploader'
import { staticColumns } from './columns'
import { useRatesUploadQuery } from '../../../api/rates'
import type { CheckboxProps } from 'antd/lib'
import type { PsychologistCSV } from './columns'

const rowKey = (row: PsychologistCSV) => row.bitrix_id

const RatesUploadPage = () => {
  const { mutateAsync, isLoading } = useRatesUploadQuery()
  const [isChecked, setChecked] = useState(false)
  const inputRef = useRef(false)

  const onChange: CheckboxProps['onChange'] = e => {
    setChecked(e.target.checked)
    inputRef.current = e.target.checked
  }

  const uploadFunction = async (csv: string) =>
    mutateAsync({
      stringCSV: csv,
      params: { updateRelationships: inputRef.current }
    })

  return (
    <>
      <Checkbox checked={isChecked} onChange={onChange}>
        Обновить цены для существующих клиентов
      </Checkbox>
      <UploadPage
        columns={staticColumns}
        formValuesType={FormDataType}
        isLoading={isLoading}
        rowKey={rowKey}
        uploadFunction={uploadFunction}
      />
    </>
  )
}

export default RatesUploadPage
