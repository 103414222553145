import type { SearchPanelFields } from '../../organisms/SearchPanel'

const checkboxYesNoIgnoreOptions = [
  {
    label: 'Да',
    value: 'true'
  },
  {
    label: 'Нет',
    value: 'false'
  },
  {
    label: 'Не учитывать',
    value: 'ignore'
  }
]

export const staticFields: SearchPanelFields = [
  {
    type: 'text',
    name: 'nameEq',
    label: 'Имя',
    placeholder: 'Введите полное имя сертификата',
    required: false,
    width: '50%'
  },
  {
    type: 'radioGroup',
    name: 'isActiveEq',
    label: 'Активен',
    options: checkboxYesNoIgnoreOptions,
    required: true,
    width: '50%'
  }
]
