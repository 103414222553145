import FormItem from 'antd/es/form/FormItem'
import React from 'react'
import { Button, Input, Row } from 'antd'
import { Loader } from '../../../atoms/Loader'
import { useUserLoadingStore } from '../../../pages/Login/store'

const EmailField = () => (
  <FormItem
    label="E-mail"
    name="email"
    rules={[
      {
        type: 'email',
        required: true,
        message: 'Пожалуйста, введите email'
      }
    ]}
  >
    <Input placeholder="Электронная почта" size="large" />
  </FormItem>
)

const PasswordField = () => (
  <FormItem
    label="Пароль"
    name="password"
    rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
  >
    <Input.Password placeholder="Пароль" size="large" />
  </FormItem>
)

export const OTPForm = () => {
  const isLoading = useUserLoadingStore()

  return (
    <div>
      {isLoading && <Loader />}
      <EmailField />
      <PasswordField />
      <Row align="bottom" justify="end">
        <Button htmlType="submit" size="large" type="primary">
          Продолжить
        </Button>
      </Row>
    </div>
  )
}
