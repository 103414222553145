import { USERS } from './users'
import { apiClient } from '.'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { ClientFullInfo } from './types/clientInfo'
import type {
  CreateSessionResponse,
  Session,
  SessionsWithTotal
} from './types/session'
import type {
  Language,
  RequesterType,
  SessionRole,
  SessionStatus,
  SessionType
} from './types/enums'
import type { PickEnumKeys } from '../app/atoms/ts/generics'
import type { UseQueryOptions } from '@tanstack/react-query'
import type { WithPaginationFilters } from './types/pagination'

const path = '/sessions'
const SESSIONS = 'SESSIONS'

export type SessionGetParams = WithPaginationFilters<{
  deletedEq?: boolean
  actNull?: boolean
  satisfactionQuestionnaireNull?: boolean
  psychologistEq?: string
  clientEq?: string
  statusEq?: SessionStatus
  sort?: string
  finishDatetimeGte?: string
  finishDatetimeLte?: string
  typeEq?: SessionType
  languageEq?: Language
  roleNe?: SessionRole
}>

type SessionDeleteParams = {
  id: string
}

type DeleteBreakParams = {
  id: string
  psychologist: string
}

export type SessionDownloadStatsParams = {
  from?: string
  to?: string
  company?: string
}

export type CreateSessionBody = {
  psychologist: string
  client: string
  type: SessionType
  language: Language
  finishDatetime: string
  startDatetime: string
  role: SessionRole
  requester: RequesterType
  legacy?: boolean
}

type CreateBreakBody = {
  psychologistId: string
  startDatetime: string
  role: SessionRole
}

export type SessionCreateBody = {
  psychologist: string
  client: string
  startDate?: string
  finishDate: string
  status: PickEnumKeys<
    SessionStatus,
    SessionStatus.lateCancellation | SessionStatus.done
  >
  type: SessionType
  language: Language
}

type ClientInfoParams = {
  id: string
}

const sessions = {
  get: (params: SessionGetParams) =>
    apiClient.get<SessionsWithTotal>(path, { params }).then(res => res.data),
  delete: ({ id }: SessionDeleteParams) =>
    apiClient.delete<Session>(`/session/${id}`),
  deleteBreak: (body: DeleteBreakParams) =>
    apiClient.post(`${path}/deleteBreak`, body),
  downloadStats: (params: SessionDownloadStatsParams) =>
    apiClient.get(`${path}/stats/`, { params }).then<string>(res => res.data),
  downloadPsyStats: (params: SessionDownloadStatsParams) =>
    apiClient
      .get(`${path}/stats_b2b`, { params })
      .then<string>(res => res.data),
  createSession: (body: CreateSessionBody | CreateBreakBody) =>
    apiClient
      .post(`${path}/createSession`, body)
      .then<CreateSessionResponse>(res => res.data),
  clientInfo: ({ id }: ClientInfoParams) =>
    apiClient
      .get(`${path}/clientInfo/${id}`)
      .then<ClientFullInfo>(res => res.data.clientInfo),
  download: (params: SessionGetParams) =>
    apiClient.get(`${path}/download`, { params }).then<string>(res => res.data)
}

const useSessionsQuery = (
  params: SessionGetParams,
  options?: UseQueryOptions<SessionsWithTotal>
) =>
  useQuery<SessionsWithTotal>(
    [SESSIONS, 'get', params],
    () => sessions.get(params),
    options
  )

export const useSessionsOrBreakCreateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [SESSIONS, 'createSession'],
    (body: CreateSessionBody | CreateBreakBody) => sessions.createSession(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SESSIONS])
        queryClient.invalidateQueries([USERS])
      }
    }
  )
}

export const useDeleteBreakQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [SESSIONS, 'deleteBreak'],
    (params: DeleteBreakParams) => sessions.deleteBreak(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SESSIONS])
      }
    }
  )
}

export const useSessionsQueryRefetchOnly = (
  params: SessionGetParams
): ReturnType<typeof useSessionsQuery> =>
  useSessionsQuery(params, refetchOnlyParams)

export const useSessionsDeleteQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [SESSIONS, 'update'],
    (params: SessionDeleteParams) => sessions.delete(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SESSIONS])
        queryClient.invalidateQueries([USERS])
      }
    }
  )
}

const useSessionsDownloadStatsQuery = (
  params: SessionDownloadStatsParams,
  options?: UseQueryOptions<string>
) =>
  useQuery<string>(
    [SESSIONS, 'download-stats'],
    () => sessions.downloadStats(params),
    options
  )

export const useSessionsDownloadStatsQueryRefetchOnly = (
  params: SessionDownloadStatsParams
): ReturnType<typeof useSessionsDownloadStatsQuery> =>
  useSessionsDownloadStatsQuery(params, {
    ...refetchOnlyParams,
    initialData: ''
  })

const useSessionsDownloadPsyStatsQuery = (
  params: SessionDownloadStatsParams,
  options?: UseQueryOptions<string>
) =>
  useQuery<string>(
    [SESSIONS, 'download-stats-pst'],
    () => sessions.downloadPsyStats(params),
    options
  )

export const useSessionsDownloadPsyStatsQueryRefetchOnly = (
  params: SessionDownloadStatsParams
): ReturnType<typeof useSessionsDownloadStatsQuery> =>
  useSessionsDownloadPsyStatsQuery(params, {
    ...refetchOnlyParams,
    initialData: ''
  })

export const useSessionsClientInfoQueryRefetchOnly = (
  params: ClientInfoParams
) =>
  useQuery<ClientFullInfo>(
    [SESSIONS, 'clientInfo', params],
    () => sessions.clientInfo(params),
    refetchOnlyParams
  )

const useSessionsDownloadQuery = (
  params: SessionGetParams,
  options?: UseQueryOptions<string>
) =>
  useQuery<string>(
    [SESSIONS, 'download'],
    () => sessions.download(params),
    options
  )

export const useSessionsDownloadQueryRefetchOnly = (
  params: SessionGetParams
): ReturnType<typeof useSessionsDownloadQuery> =>
  useSessionsDownloadQuery(params, {
    ...refetchOnlyParams,
    initialData: ''
  })
