import type { SearchPanelFields } from '../../organisms/SearchPanel'

export const staticFilters: SearchPanelFields = [
  {
    type: 'dates',
    name: 'dates',
    label: 'Дата сессии',
    placeholder: ['Дата начала', 'Дата окончания'],
    required: false,
    width: '50%'
  }
]
