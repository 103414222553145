import React, { memo, useState } from 'react'
import { AddCertificateModal } from '../../organisms/AddCertificateModal'
import { CrudPanel } from '../../molecules/CrudPanel'
import {
  LinkCertWithEntitiesModal,
  useLinkCertWithEntitiesModal
} from '../../organisms/LinkCertWithEntitiesModal'
import { Pagination } from '../../molecules/Pagination'
import { SearchPanel } from '../../organisms/SearchPanel'
import { Table } from 'antd'
import { staticFields } from './fields'
import { useCertificatesPageQueries } from './queries'
import { useColumns } from './columns'
import type { BooleanString } from '../../atoms/ts'
import type {
  Certificate,
  CertificatesResult,
  useCertificatesQuery
} from '../../../api/certificates'
import type { FC } from 'react'

export const tableName = 'certificates'

const staticTableProps = {
  pagination: false,
  rowKey: (record: Certificate) => record.name,
  scroll: {
    x: 'max-content'
  }
} as const

type CertificateTableTableProps = {
  isLoading: boolean
  refetch: ReturnType<typeof useCertificatesQuery>['refetch']
} & CertificatesResult

const CertificatesTable: FC<CertificateTableTableProps> = memo(
  ({ data, total, isLoading, refetch }) => {
    const { modal, show, hide } = useLinkCertWithEntitiesModal()

    const columns = useColumns({ refetch, showLinkModal: show })
    return (
      <>
        <Pagination tableName={tableName} total={total} />
        <Table
          columns={columns}
          dataSource={data ?? []}
          loading={isLoading}
          {...staticTableProps}
        />
        {modal.visible && (
          <LinkCertWithEntitiesModal
            onCancel={hide}
            onSuccess={refetch}
            {...modal}
          />
        )}
      </>
    )
  }
)

export type CertificateFiltersParams = {
  nameEq: string
  isActiveEq: BooleanString
}

const Certificates = () => {
  const [showAddCertificateModal, setShowAddCertificateModal] = useState(false)
  const { refetch, download, isDownloading, ...pageQueries } =
    useCertificatesPageQueries(tableName)

  return (
    <>
      <SearchPanel
        fields={staticFields}
        onSearch={pageQueries.handleRefetchWithNotification}
        tableName={tableName}
      />
      <CrudPanel
        createButtonProps={{
          onClick: () => {
            setShowAddCertificateModal(true)
          },
          children: 'Добавить сертификат'
        }}
        downloadButtonProps={{
          onClick: download,
          children: 'Сохранить таблицу в csv',
          hidden: !pageQueries.total,
          loading: isDownloading
        }}
      />
      <CertificatesTable
        data={pageQueries.data}
        isLoading={pageQueries.isLoading}
        refetch={refetch}
        total={pageQueries.total}
      />
      {showAddCertificateModal && (
        <AddCertificateModal
          onCancel={() => {
            setShowAddCertificateModal(false)
          }}
        />
      )}
    </>
  )
}

export default Certificates
