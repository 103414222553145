import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import type { FC, PropsWithChildren } from 'react'

const root = document.documentElement as HTMLDivElement

const useSize = () => {
  const refRoot = useRef(root)
  const [size, setSize] = useState<DOMRect>()

  useLayoutEffect(() => {
    setSize(refRoot.current.getBoundingClientRect())
  }, [])

  useEffect(() => {
    if (typeof size !== 'undefined') {
      const vh = size.height * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      document.documentElement.style.setProperty('--height', `${size.height}px`)
      document.documentElement.style.setProperty('--width', `${size.width}px`)
      document.documentElement.style.setProperty(
        '--scroll-height',
        `${refRoot.current.scrollHeight}px`
      )
    }
  }, [size])

  useResizeObserver(refRoot.current, entry =>
    setSize(entry.contentRect as DOMRect)
  )
  return size
}

const ResizeContext = createContext({})

export const ResizeProvider: FC<PropsWithChildren> = ({ children }) => {
  const size = useSize()

  return (
    <ResizeContext.Provider value={{ size }}>{children}</ResizeContext.Provider>
  )
}
