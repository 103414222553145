import React from 'react'
import { Tooltip } from 'antd'
import { renderWithRequiredStar } from '../../atoms/WithRequiredStar'
import type { CertificateCSV } from '../../../api/types/promocode'
import type { ColumnsType } from 'antd/es/table'

export const staticColumns: ColumnsType<CertificateCSV> = [
  {
    title: 'Индекс',
    dataIndex: 'key',
    fixed: 'left',
    width: 100,
    render: (value: number) => value
  },
  {
    title: renderWithRequiredStar('ID бейджа'),
    dataIndex: 'id',
    fixed: 'left',
    width: 180,
    render: (value: string) => <Tooltip title={value}>{value}</Tooltip>
  },
  {
    title: renderWithRequiredStar('Список психологов с разделителем'),
    dataIndex: 'psychologistIds'
  }
]
