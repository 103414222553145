import css from './index.module.scss'
import dayjs from 'dayjs'
import { AXIOS_INSTANCE } from '../../api/axios'
import { apiClient } from '../../api'
import { create } from 'zustand'
import { message } from 'antd'
import { parseErrorToString } from '../lib/parsers'
import { useCallback, useEffect } from 'react'

const standartDuration = 2
const errorDuration = 3

type NotificationType = 'success' | 'info' | 'warning' | 'error'
export type Notification = {
  type: NotificationType
  message?: string
  description?: string
}
type History = Array<Notification & { key: number; date: dayjs.Dayjs }>

interface Store {
  current?: Notification
  history: History
  show: (arg: Notification) => void
}

const useNotificationStore = create<Store>()((set, get) => ({
  current: undefined,
  show: (arg: Notification) => {
    set({
      ...get(),
      current: arg,
      history: [...get().history, { ...arg, key: Date.now(), date: dayjs() }]
    })
  },
  history: []
}))

export const useNotificationHistory = () =>
  useNotificationStore(store => store.history)

export const useNotificationShow = () =>
  useNotificationStore(store => store.show)

export const useErrorNotificationShow = () => {
  const showNotification = useNotificationStore(store => store.show)

  return useCallback(
    (description: string) =>
      showNotification({
        type: 'error',
        description
      }),
    []
  )
}

export const useSuccessNotificationShow = () => {
  const showNotification = useNotificationStore(store => store.show)

  return useCallback(
    (description: string) =>
      showNotification({
        type: 'success',
        description
      }),
    []
  )
}

export const useGlobalNotification = () => {
  const [messageApi, contextHolder] = message.useMessage()

  const showMsg = (current: Notification) => {
    messageApi.open({
      type: current.type,
      content: current.description,
      className: css.message,
      duration: current.type !== 'error' ? standartDuration : errorDuration
    })
  }

  useEffect(() => {
    useNotificationStore.subscribe(({ current }) => {
      if (current) {
        showMsg(current)
      }
    })
  }, [])

  return contextHolder
}

export const useAxiosErrorInterceptorsWithNotifications = () => {
  const showErrorNotification = useErrorNotificationShow()

  function errorHandler(error: unknown) {
    showErrorNotification(parseErrorToString(error))
    return Promise.reject(error)
  }

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      undefined,
      errorHandler
    )

    const interceptorNew = AXIOS_INSTANCE.interceptors.response.use(
      undefined,
      errorHandler
    )

    return () => {
      apiClient.interceptors.response.eject(interceptor)
      AXIOS_INSTANCE.interceptors.response.eject(interceptorNew)
    }
  }, [])
}
