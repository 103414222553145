/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * YouTalk CRM API
 * OpenAPI spec version: 1.0.0
 */
import type {
  BadgeUpdate200,
  BadgeUpdateBody,
  BadgesGet200,
  BadgesGetParams
} from './index.schemas'
import { customInstance } from '../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * @summary Retrieve badges
 */
export const badgesGet = (
    params?: BadgesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BadgesGet200>(
      {url: `/badges`, method: 'GET',
        params
    },
      options);
    }
  /**
 * @summary Badge certificate
 */
export const badgeUpdate = (
    id: string,
    badgeUpdateBody: BadgeUpdateBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BadgeUpdate200>(
      {url: `/badges/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: badgeUpdateBody
    },
      options);
    }
  export type BadgesGetResult = NonNullable<Awaited<ReturnType<typeof badgesGet>>>
export type BadgeUpdateResult = NonNullable<Awaited<ReturnType<typeof badgeUpdate>>>
