/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import classNames from 'classnames'
import css from './index.module.scss'
import dayjs from 'dayjs'
import { ButtonGroup } from './ButtonGroup'
import { Col, Collapse, Form, Row } from 'antd'
import { SearchFormItem } from './SearchFormItem'
import { SearchOutlined } from '@ant-design/icons'
import { SelectMany } from './SelectMany'
import { useTableFiltersStore } from './store'
import type { FC, PropsWithChildren, ReactNode } from 'react'
import type { Filter } from './store'
import type { FormInstance } from 'antd/es/form/Form'
import type { SearchOption } from '../../atoms/Select'

const { Panel } = Collapse

type Width = '25%' | '50%' | '100%'

type FieldCommon = {
  width?: Width
  required: boolean
  name: string
  label: string
  tooltip?: string
  disabled?: boolean
  hidden?: boolean
  help?: ReactNode
}

export type AsyncSelectSearchField = {
  type: 'searchSelect'
  placeholder: string
  options: SearchOption[]
  loading?: boolean
  dropdownLoading?: boolean
  onSearch?: (value: any) => void
  onClear?: () => void
} & FieldCommon

export type AsyncSelectManyField = FieldCommon & {
  type: 'searchSelectMany'
  placeholder: string
  options: SearchOption[]
  loading?: boolean
  dropdownLoading?: boolean
  onSearch?: (value: any) => void
  onClear?: () => void
}

export type SelectSearchField = {
  type: 'select'
  placeholder: string
  options: SearchOption[]
  loading?: boolean
} & FieldCommon

export type DatesSearchField = {
  type: 'dates'
  placeholder: [string, string]
} & FieldCommon

export type NumberSearchField = {
  type: 'number'
  placeholder: string
  max?: number
  prefix?: string
  step?: number | string
} & FieldCommon

export type TextSearchField = {
  type: 'text'
  placeholder: string
} & FieldCommon

type CustomField = {
  type: 'custom'
  placeholder: string
  Field: FC
} & FieldCommon

type RadioGroupOption = {
  label: ReactNode
  value: string
  disabled?: boolean
}

type RadioGroupField = {
  type: 'radioGroup'
  options: RadioGroupOption[]
} & FieldCommon

export type MonthYearDateField = {
  type: 'date'
  placeholder: string
} & FieldCommon

export type SearchField =
  | AsyncSelectManyField
  | AsyncSelectSearchField
  | SelectSearchField
  | DatesSearchField
  | NumberSearchField
  | TextSearchField
  | CustomField
  | RadioGroupField
  | MonthYearDateField

export type SearchPanelFields = SearchField[]

type Props = Partial<{
  onSelect: (value: any) => void
  onSearch: () => void | any
  fields: SearchPanelFields
  buttonGroupHidden: boolean
}> & { tableName: string }

const SearchCollapse: FC<PropsWithChildren> = ({ children }) => (
  <Collapse
    className={css.collapse}
    defaultActiveKey={['search']}
    expandIconPosition="start"
    size="small"
  >
    <Panel key="search" extra={<SearchOutlined />} header="Параметры поиска">
      {children}
    </Panel>
  </Collapse>
)

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: 'Поле ${label} обязательно для заполнения'
}

const getFieldWidthCSS = (width: SearchField['width']) =>
  classNames({
    [css.fullWidth]: width === '100%' || !width,
    [css.halfFullWidth]: width === '50%',
    [css.quarterFullWidth]: width === '25%'
  })

const useInitialValues = (
  fields?: SearchPanelFields,
  tableName?: string,
  form?: FormInstance<any>
) => {
  const { tables } = useTableFiltersStore()

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const initValuesFromStore = fields?.reduce(
      (acc: Record<string, unknown>, { name, type }) => {
        if (!tableName || typeof tables[tableName] === 'undefined') {
          return acc
        }
        if (type === 'dates' && tables[tableName]) {
          const cachedDates = ((tables[tableName]![name] ?? []) as string[])
            .filter(Boolean)
            .map(date => dayjs(date))

          if (cachedDates.length > 0) {
            acc[name] = cachedDates
          } else {
            acc[name] = undefined
          }
        } else if (type === 'date' && tables[tableName]) {
          const cachedDate = tables[tableName]![name]
          if (dayjs(cachedDate as string).isValid()) {
            acc[name] = dayjs(cachedDate as string)
          } else {
            acc[name] = undefined
          }
        } else {
          acc[name] = tables[tableName]![name]
        }
        return acc
      },
      {}
    )

    if (tableName && tables[tableName] && form) {
      form.setFieldsValue(initValuesFromStore)
    }
  }, [])
}

const formStaticProps = {
  className: css.fullWidth,
  layout: 'vertical'
} as const

type UseFormHandlersInput = Omit<Props, 'fields'> & { form: FormInstance<any> }

const useFormHandlers = ({
  form,
  tableName,
  onSearch
}: UseFormHandlersInput) => {
  const { setFilters } = useTableFiltersStore()
  return {
    onValuesChange: (_: any, values: Filter) => {
      setFilters(tableName, values)
    },
    onFinish: useCallback(() => {
      typeof onSearch === 'function' && onSearch()
    }, [onSearch]),
    onReset: useCallback(() => {
      form.resetFields()
      setFilters(tableName, {})
    }, [])
  }
}

export const SearchPanel: FC<Props> = ({
  onSearch,
  fields,
  tableName,
  buttonGroupHidden
}) => {
  const [form] = Form.useForm()
  const { onFinish, onReset, onValuesChange } = useFormHandlers({
    form,
    tableName,
    onSearch
  })
  const { setFilters, tables } = useTableFiltersStore()
  useInitialValues(fields, tableName, form)
  useLayoutEffect(() => {
    tableName && !tables[tableName] && setFilters(tableName, {})
  }, [tableName])
  const onChange = (_: any, values: Filter) => {
    onValuesChange(_, values)
  }
  return (
    <SearchCollapse>
      <Form
        {...formStaticProps}
        form={form}
        name={tableName}
        onFinish={onFinish}
        onValuesChange={onChange}
        validateMessages={validateMessages}
      >
        <Row gutter={8}>
          {(fields ?? []).map(field =>
            field.type === 'searchSelectMany' ? (
              <SelectMany
                key={field.name}
                fieldOptions={field}
                label={field.label}
                name={field.name}
              />
            ) : (
              <Col key={field.name} className={getFieldWidthCSS(field.width)}>
                <SearchFormItem field={field} />
              </Col>
            )
          )}
        </Row>
        {buttonGroupHidden !== true && <ButtonGroup onReset={onReset} />}
      </Form>
    </SearchCollapse>
  )
}
