import React from 'react'
import css from './index.module.scss'
import { Spin } from 'antd'
import type { Company } from '../../../api/companies'
import type { Rate } from '../../../api/types/rate'
import type { ReactElement } from 'react'
import type { User, UserPsychologist } from '../../../api/types/user'

export type SearchOption = {
  label?: string
  value: string
}

export const staticSelectProps = {
  allowClear: true,
  showSearch: true,
  optionFilterProp: 'children',
  placeholder: 'Выберите'
}

const filterOption = (input: string, option?: SearchOption) =>
  (option?.label?.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())

export const staticSelectPropsWithFilters = {
  ...staticSelectProps,
  filterOption,
  filterSort: (optionA: SearchOption, optionB: SearchOption) =>
    (optionA.label ?? '')
      .toLowerCase()
      .localeCompare((optionB.label ?? '').toLowerCase())
}

export const getOptionsPsychologist = (users: UserPsychologist[]) =>
  users.map(({ _id, name, familyName }) => ({
    value: _id,
    label: [familyName, name].filter(Boolean).join(' ')
  }))

export const getOptionsClient = (users: User[]) =>
  users.map(({ id, name, leadId }) => ({
    value: id,
    label: [leadId, name].filter(Boolean).join(' ')
  }))

export const getOptionsRates = (rates: Rate[]) =>
  rates.map(({ id, name }) => ({
    value: id,
    label: name
  }))

export const getOptionsCompany = (companies: Company[]) =>
  companies.map(({ id, name }) => ({
    value: id,
    label: name
  }))

export const NotFoundContentWithLoading = ({
  loading
}: {
  loading?: boolean
}): ReactElement =>
  loading ? (
    <div className={css.dropdownLoader}>
      <Spin />
    </div>
  ) : (
    <span>Ничего не найдено</span>
  )
