import { useEffect } from 'react'
import type { Pagination } from '../molecules/Pagination/store'

export const usePaginationChangeEffect = (
  params: Pagination | undefined,
  refetch: () => any
) => {
  useEffect(() => {
    refetch()
  }, [params])
}
