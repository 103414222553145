import { nonNullishParams } from '../../../api/utils'
import { parseBooleanString } from '../../lib/parsers'
import { useCallback } from 'react'
import {
  useCertificatesDownloadQueryRefetchOnly,
  useCertificatesQuery
} from '../../../api/certificates'
import { useDownloadCsv } from '../../atoms/useDownloadCsv'
import { usePaginationByName } from '../../molecules/Pagination/store'
import { usePaginationChangeEffect } from '../../atoms/usePaginationChangeEffect'
import { useSuccessNotificationShow } from '../../store/notifications'
import { useTableFiltersByName } from '../../organisms/SearchPanel/store'
import type { CertificateFiltersParams } from '.'
import type { CertificatesGetParams } from '../../../api/generated/index.schemas'

export const useFiltersParams = (
  filters?: ReturnType<typeof useTableFiltersByName<CertificateFiltersParams>>
) =>
  nonNullishParams<CertificatesGetParams>({
    nameEq: filters?.nameEq,
    isActiveEq: parseBooleanString(filters?.isActiveEq)
  })

export const useCertificatesPageQueries = (tableName: string) => {
  const filters = useTableFiltersByName<CertificateFiltersParams>(tableName)
  const filtersParams = useFiltersParams(filters)
  const paginationParams = usePaginationByName(tableName)
  const showSuccessNotification = useSuccessNotificationShow()

  const { refetch: downloadCertificates } =
    useCertificatesDownloadQueryRefetchOnly(filtersParams)
  const { download, isDownloading } = useDownloadCsv({
    refetch: downloadCertificates,
    filename: 'certificates.csv'
  })

  const { data, refetch, isFetched, fetchStatus, isFetching } =
    useCertificatesQuery({
      ...filtersParams,
      skip: paginationParams?.skip,
      limit: paginationParams?.limit
    })

  const handleRefetchWithNotification = useCallback(() => {
    refetch().then(res => {
      if (res.status === 'success') {
        showSuccessNotification('Список сертификатов успешно обновлен')
      }
    })
  }, [refetch])

  usePaginationChangeEffect(paginationParams, refetch)

  return {
    data: data?.data,
    total: data?.total,
    isLoading: isFetching,
    isFetched: isFetched || fetchStatus === 'fetching',
    refetch,
    handleRefetchWithNotification,
    download,
    isDownloading
  }
}
