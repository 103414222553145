import { PROMOCODES } from './promocodes'
import {
  companiesGet,
  companyCreate,
  companyGet,
  companyUpdate
} from './generated/companies'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  CompaniesGet200DataItem,
  CompaniesGetParams,
  CompanyCreateBody,
  CompanyGet200,
  CompanyUpdateBody
} from './generated/index.schemas'
import type {
  CompaniesGetResult,
  CompanyGetResult
} from './generated/companies'
import type { UseQueryOptions } from '@tanstack/react-query'

const COMPANIES = 'COMPANIES'
export type Company = CompaniesGet200DataItem
export type CompanyItem = CompanyGet200

export const useCompaniesQuery = (
  params: CompaniesGetParams,
  options?: UseQueryOptions<CompaniesGetResult>
) =>
  useQuery<CompaniesGetResult>(
    [COMPANIES, 'get', params],
    () => companiesGet(params),
    options
  )

export const useCompanyQuery = (
  id: string,
  options?: UseQueryOptions<CompanyGetResult>
) =>
  useQuery<CompanyGetResult>(
    [COMPANIES, 'get', id],
    () => companyGet(id),
    options
  )

export const useCompanyCreateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [COMPANIES, 'create'],
    (body: CompanyCreateBody) => companyCreate(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COMPANIES, 'get'])
      }
    }
  )
}

export const useCompanyUpdateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [COMPANIES, 'update'],
    ({ id, ...body }: CompanyUpdateBody & { id: string }) =>
      companyUpdate(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COMPANIES, 'get'])
        queryClient.invalidateQueries([PROMOCODES])
      }
    }
  )
}
