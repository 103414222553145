import React, { useCallback } from 'react'
import classNames from 'classnames'
import css from './index.module.scss'
import { Button, Row, Space, Statistic, Typography } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import {
  Screens2FA,
  useUserDeadlineToken,
  useUserLogout,
  useUserOtpTokenStore,
  useUserSetScreenStore
} from '../../../pages/Login/store'

const { Countdown } = Statistic

const TextContent = () => {
  const logout = useUserLogout()
  const deadline = useUserDeadlineToken()
  const { email } = useUserOtpTokenStore()
  return (
    <>
      <Typography.Title level={3}>Письмо отправлено</Typography.Title>
      <MailOutlined className={css.fontSize98} />
      <Typography.Paragraph>
        На ваш электронный адрес{' '}
        <Typography.Paragraph strong className={css.noMargin}>
          {email}
        </Typography.Paragraph>{' '}
        было отправлено письмо с четырехзначным числом
      </Typography.Paragraph>
      <Typography.Paragraph>
        Срок действия текущего токена истекает через{' '}
        <Typography.Paragraph strong className={css.noMargin}>
          <Countdown
            className={css.countdown}
            format="mm:ss"
            onFinish={logout}
            value={deadline}
          />
        </Typography.Paragraph>
      </Typography.Paragraph>
    </>
  )
}

export const LetterScreen = () => {
  const setScreen = useUserSetScreenStore()
  const next = useCallback(() => setScreen(Screens2FA.LOGIN), [])

  return (
    <Space
      className={classNames([css.textAlignCenter, css.fullWidth])}
      direction="vertical"
    >
      <TextContent />
      <Row align="bottom" justify="end">
        <Button onClick={next} size="large" type="primary">
          Продолжить
        </Button>
      </Row>
    </Space>
  )
}
