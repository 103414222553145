import { CurrencySigns } from './transactionCurrency'
import { PromocodeCurrency } from '../../../api/types/enums'

export const promocodeCurrencySignMap = (currency: PromocodeCurrency) => {
  switch (currency) {
    case PromocodeCurrency.RUB:
      return CurrencySigns.RUB
    case PromocodeCurrency.EUR:
      return CurrencySigns.EUR
    case PromocodeCurrency.ANY:
      return `${CurrencySigns.RUB}, ${CurrencySigns.EUR}`
  }
}
