import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import css from './index.module.scss'
import { Pagination as AntdPagination, Typography } from 'antd'
import { isEmpty } from 'ramda'
import { usePaginationByName, usePaginationStore } from './store'
import type { FC } from 'react'

interface Props {
  tableName: string
  total?: number
}

export const Pagination: FC<Props> = ({ tableName, total }) => {
  const [prevPageSize, setPrevPageSize] = useState<number>(10)
  const { setPagination, setSize } = usePaginationStore()
  const pagination = usePaginationByName(tableName)
  useLayoutEffect(() => {
    if (isEmpty(pagination) || !pagination) {
      setPagination(tableName, 1)
    }
  }, [])

  const currentPage = useMemo(() => {
    if (pagination?.skip && pagination.limit) {
      return Math.floor(pagination.skip / pagination.limit) + 1
    }
    return 1
  }, [pagination])

  const onChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize !== prevPageSize) {
        setPrevPageSize(pageSize)
        setPagination(tableName, page)
        return
      }
      setPagination(tableName, page)
    },
    [prevPageSize, pagination?.limit]
  )
  return total ? (
    <div className={css.flexRow}>
      <AntdPagination
        current={currentPage}
        onChange={onChange}
        onShowSizeChange={(_, size) => {
          setSize(tableName, size)
        }}
        {...(pagination?.limit ? { pageSize: pagination.limit } : {})}
        total={total}
      />
      <Typography.Paragraph strong>
        {['Всего найдено:', total].join(' ')}
      </Typography.Paragraph>
    </div>
  ) : null
}
