import React from 'react'
import css from './index.module.scss'
import { Header } from '../organisms/Header'
import { Layout } from 'antd'
import { Navigation } from '../organisms/Navigation'
import { Outlet } from 'react-router-dom'
import type { FC } from 'react'

const { Content, Footer } = Layout

export const AppLayout: FC = () => (
  <Layout className={css.layout}>
    <Content className={css.layoutContent}>
      <Layout className={css.page}>
        <Navigation className={css.navigation} />
        <Content className={css.pageContent}>
          <Header />
          <Outlet />
        </Content>
      </Layout>
    </Content>
    <Footer className={css.footer}>YouTalk ©2023</Footer>
  </Layout>
)
