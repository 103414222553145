import React from 'react'
import { CrudPanel } from '../../molecules/CrudPanel'
import { DownloadLink } from '../../atoms/DownloadLink'
import { SearchPanel } from '../../organisms/SearchPanel'
import { getDownloadName, getLinkCSV } from '../../atoms/DownloadLink/utils'
import { staticFilters } from './fields'
import { useB2BStatsPageQueries } from './queries'

export const tableName = 'psy-sessions-stats'

const PsyPaymentStats = () => {
  const pageQueries = useB2BStatsPageQueries()
  const fields = staticFilters

  return (
    <>
      <SearchPanel buttonGroupHidden fields={fields} tableName={tableName} />
      <CrudPanel
        downloadButtonProps={{
          onClick: () => {
            pageQueries.refetch()
          },
          loading: pageQueries.isLoading,
          children: 'Сгенерировать CSV'
        }}
      />
      {pageQueries.data && (
        <DownloadLink
          downloadName={getDownloadName('sessions-stats-psy')}
          link={getLinkCSV(pageQueries.data)}
        />
      )}
    </>
  )
}

export default PsyPaymentStats
