import React from 'react'
import RouterConfig from './providers/RouterConfig'
import ThemeProvider from './providers/ThemeProvider'
// eslint-disable-next-line no-restricted-imports
import ruRU from 'antd/lib/locale/ru_RU'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ResizeProvider } from './providers/ResizeProvider'
import {
  useAxiosErrorInterceptorsWithNotifications,
  useGlobalNotification
} from './store/notifications'
import { useRequestInterceptorsWithToken } from '../api'
import { useRequestInterceptorsWithToken2 } from '../api/axios'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 60 * 1000
    }
  }
})

function App() {
  const contextHolder = useGlobalNotification()
  useAxiosErrorInterceptorsWithNotifications()
  useRequestInterceptorsWithToken()
  useRequestInterceptorsWithToken2()

  return (
    <ConfigProvider locale={ruRU}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ResizeProvider>
              <RouterConfig />
              {contextHolder}
            </ResizeProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </ConfigProvider>
  )
}

export default App
