import * as t from 'io-ts'
import { positiveNumber } from '../../lib/io-ts/number'

export const FormDataType = t.array(
  t.interface({
    bitrixId: t.string,
    ratio: positiveNumber,
    priceIndividualRussian: positiveNumber,
    priceIndividualEnglish: positiveNumber,
    priceFamilyRussian: positiveNumber,
    priceFamilyEnglish: positiveNumber,
    priceSyncChatRussian: positiveNumber,
    priceSyncChatEnglish: positiveNumber,
    priceAsyncChatRussian: positiveNumber,
    priceAsyncChatEnglish: positiveNumber,
    priceForClientIndividualRussian: positiveNumber,
    priceForClientIndividualEnglish: positiveNumber,
    priceForClientFamilyRussian: positiveNumber,
    priceForClientFamilyEnglish: positiveNumber,
    priceForClientSyncChatRussian: positiveNumber,
    priceForClientSyncChatEnglish: positiveNumber,
    priceForClientAsyncChatRussian: positiveNumber,
    priceForClientAsyncChatEnglish: positiveNumber
  })
)
