import Form, { useForm, useWatch } from 'antd/es/form/Form'
import React, { useCallback, useState } from 'react'
import { CompanyField, UserField } from '../LinkPromoWithEntitiesModal/fields'
import { Loader } from '../../atoms/Loader'
import { Modal } from '../../molecules/Modal'
import { useCertificateUpdateQuery } from '../../../api/certificates'
import { useSuccessNotificationShow } from '../../store/notifications'
import type { Certificate } from '../../../api/certificates'
import type { FC } from 'react'
import type { FormInstance } from 'antd/es/form/Form'
import type { FormValues } from '../LinkPromoWithEntitiesModal/index.types'

type Props = {
  onCancel: () => void
  certificateId?: string
  certificateName?: string
  certificateCompanyId?: string
  certificateUserId?: string
  onSuccess: () => any
}

export const useLinkCertWithEntitiesModal = () => {
  const [modal, setModal] = useState<{
    visible: boolean
    certificateId?: string
    certificateName?: string
    certificateCompanyId?: string
    certificateUserId?: string
  }>({
    visible: false
  })

  return {
    modal,
    show: useCallback((record: Certificate) => {
      setModal({
        visible: true,
        certificateCompanyId: record.company?.id,
        certificateId: record.id,
        certificateName: record.name,
        certificateUserId: record.user?.id
      })
    }, []),
    hide: useCallback(() => {
      setModal({
        visible: false
      })
    }, [])
  }
}

type useOnFinishInput = {
  certificateId?: string
  onSuccessFinish: () => void
}

const useOnFinish = ({ certificateId, onSuccessFinish }: useOnFinishInput) => {
  const { mutateAsync: update, isLoading } = useCertificateUpdateQuery()
  const onFinish = useCallback((values: FormValues) => {
    if (certificateId) {
      update({
        id: certificateId,
        ...values
      }).then(() => {
        onSuccessFinish()
      })
    }
  }, [])

  return {
    onFinish,
    isLoading
  }
}

type LinkFormProps = {
  isLoading: boolean
  onFinish: (values: FormValues) => void
  certificateUserId?: string
  certificateCompanyId?: string
  form: FormInstance<FormValues>
}

const LinkForm: FC<LinkFormProps> = ({
  isLoading,
  form,
  certificateCompanyId,
  certificateUserId,
  onFinish
}) => (
  <Form
    disabled={isLoading}
    form={form}
    initialValues={{
      user: certificateUserId,
      company: certificateCompanyId
    }}
    layout="vertical"
    onFinish={onFinish}
  >
    <UserField form={form} />
    <CompanyField />
    {isLoading && <Loader />}
  </Form>
)

// eslint-disable-next-line max-lines-per-function
export const LinkCertWithEntitiesModal: FC<Props> = ({
  onCancel,
  certificateId,
  certificateName,
  certificateCompanyId,
  certificateUserId,
  onSuccess
}) => {
  const [form] = useForm<FormValues>()
  const showSuccess = useSuccessNotificationShow()
  const companyId = useWatch('companyId', form)
  const userId = useWatch('userId', form)
  const { onFinish, isLoading } = useOnFinish({
    onSuccessFinish: () => {
      showSuccess(`Клиент у ${certificateName} успешно перепривязан`)
      onSuccess()
      form.resetFields()
    },
    certificateId
  })
  return (
    <Modal
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{
        onClick: () => form.submit(),
        disabled:
          isLoading ||
          (userId === certificateUserId && companyId === certificateCompanyId)
      }}
      onCancel={onCancel}
      // eslint-disable-next-line max-len
      title={`Изменить привязанного клиента / компанию  у сертификата ${certificateName}`}
    >
      <LinkForm
        certificateCompanyId={certificateCompanyId}
        certificateUserId={certificateUserId}
        form={form}
        isLoading={isLoading}
        onFinish={onFinish}
      />
    </Modal>
  )
}
