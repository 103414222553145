import React, { memo } from 'react'
import css from './index.module.scss'
import { Button, Layout, Menu, Space, Typography } from 'antd'
import {
  CopyrightOutlined,
  FileDoneOutlined,
  LogoutOutlined,
  PercentageOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined
} from '@ant-design/icons'
import { NavLink, useLocation } from 'react-router-dom'
import { useUserInfoStore, useUserLogout } from '../../pages/Login/store'

const { Sider } = Layout

const { Text } = Typography

enum NavigationKeys {
  PSYCHOLOGIST = 'psychologists',
  PSYCHOLOGIST_SESSIONS = 'psychologists.sessions',
  PSYCHOLOGIST_RELATIONSHIPS = 'psychologists.relationships',
  PSYCHOLOGIST_SCHEDULE = 'psychologists.schedule',
  PSYCHOLOGIST_PROFILE = 'psychologists.profile',
  PSYCHOLOGIST_SEARCH = 'psychologists.search',
  PSYCHOLOGIST_BADGES = 'psychologists.badges',

  CLIENTS = 'clients',
  CLIENTS_TRANSACTIONS = 'clients.transactions',
  CLIENTS_LIST = 'clients.list',

  PROMOCODES = 'promocodes',

  UPLOADS = 'uploads',
  UPLOADS_LIST = 'uploads.list',
  UPLOADS_PROMOCODES = 'uploads.promocodes',
  UPLOADS_CERTIFICATES = 'uploads.certificates',
  UPLOADS_BADGES = 'uploads.badges',
  UPLOADS_PSYCHOLOGISTS = 'uploads.psychologists',
  UPLOADS_RATES = 'uploads.RATES',
  B2B = 'b2b',
  B2B_SESSIONS_STATS = 'b2b.sessions-stats',
  PSY_SESSIONS_STATS = 'psy.sessions-stats',

  COMPANIES = 'companies',
  CERTIFICATES = 'CERTIFICATES'
}

const staticMenu = [
  {
    key: NavigationKeys.PSYCHOLOGIST,
    icon: <UserOutlined />,
    label: 'Психологи',
    children: [
      {
        key: NavigationKeys.PSYCHOLOGIST_SESSIONS,
        label: <NavLink to="/psychologists/sessions">Сессии</NavLink>
      },
      {
        key: NavigationKeys.PSYCHOLOGIST_RELATIONSHIPS,
        label: <NavLink to="/psychologists/relationships">Отношения</NavLink>
      },
      {
        key: NavigationKeys.PSYCHOLOGIST_SCHEDULE,
        label: <NavLink to="/psychologists/schedule">Расписание</NavLink>
      },
      {
        key: NavigationKeys.PSYCHOLOGIST_PROFILE,
        label: <NavLink to="/psychologists/profile">Профили</NavLink>
      },
      {
        key: NavigationKeys.PSYCHOLOGIST_SEARCH,
        label: <NavLink to="/psychologists/search">Поиск</NavLink>
      },
      {
        key: NavigationKeys.PSYCHOLOGIST_BADGES,
        label: <NavLink to="/psychologists/badges">Бейджи</NavLink>
      }
    ]
  },
  {
    key: NavigationKeys.CLIENTS,
    icon: <UserOutlined />,
    label: 'Клиенты',
    children: [
      {
        key: NavigationKeys.CLIENTS_TRANSACTIONS,
        label: <NavLink to="/clients/transactions">Транзакции</NavLink>
      },
      {
        key: NavigationKeys.CLIENTS_LIST,
        label: <NavLink to="/clients/list">Список</NavLink>
      }
    ]
  },
  {
    key: NavigationKeys.COMPANIES,
    icon: <CopyrightOutlined />,
    label: <NavLink to="/companies">Компании</NavLink>
  },
  {
    key: NavigationKeys.CERTIFICATES,
    icon: <FileDoneOutlined />,
    label: <NavLink to="/certificates">Сертификаты</NavLink>
  },
  {
    key: NavigationKeys.PROMOCODES,
    icon: <PercentageOutlined />,
    label: <NavLink to="/promocodes">Промокоды</NavLink>
  },
  {
    key: NavigationKeys.UPLOADS,
    icon: <UploadOutlined />,
    label: 'Загрузки',
    children: [
      {
        key: NavigationKeys.UPLOADS_LIST,
        label: <NavLink to="/uploads/list">Список</NavLink>
      },
      {
        key: NavigationKeys.UPLOADS_PROMOCODES,
        label: <NavLink to="/uploads/promocodes">Промокоды</NavLink>
      },
      {
        key: NavigationKeys.UPLOADS_CERTIFICATES,
        label: <NavLink to="/uploads/certificates">Сертификаты</NavLink>
      },
      {
        key: NavigationKeys.UPLOADS_BADGES,
        label: <NavLink to="/uploads/badges">Бейджи</NavLink>
      },
      {
        key: NavigationKeys.UPLOADS_PSYCHOLOGISTS,
        label: <NavLink to="/uploads/psychologists">Психологи</NavLink>
      },
      {
        key: NavigationKeys.UPLOADS_RATES,
        label: <NavLink to="/uploads/rates">Цены</NavLink>
      }
    ]
  },
  {
    key: NavigationKeys.B2B,
    icon: <TeamOutlined />,
    label: 'B2B-отчёты',
    children: [
      {
        key: NavigationKeys.B2B_SESSIONS_STATS,
        label: <NavLink to="/b2b/sessions-stats">По компании</NavLink>
      },
      {
        key: NavigationKeys.PSY_SESSIONS_STATS,
        label: <NavLink to="/b2b/sessions-stats-psy">По психологам</NavLink>
      }
    ]
  }
]

const useMenuItems = () => staticMenu

const getSelectedKeys = (paths: string[]) => {
  const joinedPath = paths.join('.')

  return [joinedPath.endsWith('.') ? joinedPath.slice(0, -1) : joinedPath]
}

type NavigationProps = {
  className?: string
}

export const Navigation = memo(({ className }: NavigationProps) => {
  const items = useMenuItems()
  const { pathname } = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, ...paths] = pathname.split('/')
  const [parentPathname] = paths
  const logout = useUserLogout()
  const userInfo = useUserInfoStore()

  return (
    <Space className={className} direction="vertical">
      <Space className={css.infoWithLogout} direction="vertical">
        <Text ellipsis>{userInfo?.fullName}</Text>
        <Button
          className={css.fullWidth}
          icon={<LogoutOutlined />}
          onClick={logout}
        >
          Выйти
        </Button>
      </Space>
      <Sider className={css.sider}>
        <Menu
          className={css.menu}
          defaultOpenKeys={[parentPathname]}
          items={items}
          mode="inline"
          selectedKeys={getSelectedKeys(paths)}
        />
      </Sider>
    </Space>
  )
})
