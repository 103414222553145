import { getLinkCSV } from './DownloadLink/utils'
import { useCallback, useState } from 'react'
import { useCopyToClipboard } from './useCopiedToClipboard'
import { useSuccessNotificationShow } from '../store/notifications'
import type { QueryObserverResult } from '@tanstack/react-query'

type UseDownloadCsvParams = {
  refetch: () => Promise<QueryObserverResult<string, unknown>>
  filename: string
}

export const useDownloadCsv = ({ refetch, filename }: UseDownloadCsvParams) => {
  const [, copy] = useCopyToClipboard()
  const [isDownloading, setDownloading] = useState(false)

  const showSuccessNotification = useSuccessNotificationShow()

  const download = useCallback(() => {
    setDownloading(true)
    refetch()
      .then(res => {
        if (res.data) {
          const url = getLinkCSV(res.data)
          showSuccessNotification(
            `Сформирована ссылка ${url} для скачивания csv`
          )
          copy(url)

          const element = document.createElement('a')
          element.setAttribute('href', url)
          element.setAttribute('download', filename)
          element.style.display = 'none'
          document.body.append(element)
          element.click()
          element.remove()
        }
      })
      .finally(() => setDownloading(false))
  }, [])
  return {
    download,
    isDownloading
  }
}
