import { create } from 'zustand'

const DEFAULT_PAGE_LIMIT = 10

export type Pagination = {
  skip: number
  limit?: number
  page?: number
}

interface Store {
  paginations: { [key: string]: Pagination | undefined }
  setPagination: (tableName: string, page: number) => void
  setSize: (tableName: string, size: number) => void
}

export const usePaginationStore = create<Store>()((set, get) => ({
  paginations: {},
  setPagination: (tableName: string, page: number) => {
    const currentTable = get().paginations[tableName]
    const currentLimit = currentTable?.limit
      ? currentTable.limit
      : DEFAULT_PAGE_LIMIT
    set({
      ...get(),
      paginations: {
        ...get().paginations,
        [tableName]: {
          limit: currentLimit,
          skip: page === 1 ? 0 : currentLimit * (page - 1),
          page
        }
      }
    })
  },
  setSize: (tableName: string, size: number) => {
    set({
      ...get(),
      paginations: {
        ...get().paginations,
        [tableName]: {
          limit: size,
          skip: 0
        }
      }
    })
  }
}))

export const usePaginationByName = (
  tableName: string
): Pagination | undefined => {
  const { paginations } = usePaginationStore()

  return paginations[tableName]
}
