import Dragger from 'antd/es/upload/Dragger'
import Form, { useForm, useWatch } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Title from 'antd/es/typography/Title'
import { CrudPanel } from '../../molecules/CrudPanel'
import { FileOutlined, UploadOutlined } from '@ant-design/icons'
import { Pagination, Table, Typography } from 'antd'
import { SpinnerText } from '../../atoms/SpinnerText'
import { csv2Json } from '../../lib/converters'
import { useCheckValuesWithErrorNotification } from '../../lib/io-ts/useCheckValues'
import { useCopyToClipboard } from '../../atoms/useCopiedToClipboard'
import { useSuccessNotificationShow } from '../../store/notifications'
import type { ColumnsType } from 'antd/es/table'
import type { FC } from 'react'
import type { Mixed } from 'io-ts'
import type { PaginationProps } from 'antd/es/pagination'
import type { RcFile } from 'antd/es/upload'
import type { UploadProps } from 'antd'

type CsvUploadFunction = (csv: string) => Promise<{ importId: string }>

type UploaderProps = {
  value?: { file: RcFile; fileList: RcFile[] }
  onChange?: UploadProps['onChange']
  afterReadCSV: (stringCSV: string) => void
  onReset?: () => void
}

const Uploader: FC<UploaderProps> = ({
  value,
  onChange,
  afterReadCSV,
  onReset
}) => (
  <Dragger
    accept=".csv"
    beforeUpload={file => {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.addEventListener('load', () => {
        const csvData = reader.result
        if (typeof csvData === 'string') {
          afterReadCSV(csvData)
        }
      })
    }}
    customRequest={({ onSuccess }) => {
      if (typeof onSuccess === 'function') {
        onSuccess('ok')
      }
    }}
    fileList={value?.fileList}
    iconRender={() => <FileOutlined />}
    maxCount={1}
    onChange={onChange}
    onRemove={onReset}
  >
    <UploadOutlined />
    Загрузить данные (.csv)
  </Dragger>
)

const useTableData = <T,>(stringCSV: string) => {
  const [parsedData, setParsedData] = useState<T[]>([])
  const [tableData, setTableData] = useState<T[]>([])
  const generateTableData = useCallback(
    ({ stringCSV }: { stringCSV: string }) => {
      if (stringCSV) {
        const { data } = csv2Json<T>(stringCSV.trim())
        setParsedData(data)
        setTableData(
          data.slice(0, 10).map((row, index) => ({ ...row, key: index }))
        )
      }
    },
    [stringCSV]
  )
  const onChangePagination: PaginationProps['onChange'] = pageNumber => {
    const currentPageStartInd = (pageNumber - 1) * 10
    const currentPageEndInd = pageNumber * 10
    setTableData(
      parsedData
        .slice(currentPageStartInd, currentPageEndInd)
        .map((row, index) => ({ ...row, key: index + currentPageStartInd }))
    )
  }
  useEffect(() => {
    stringCSV && generateTableData({ stringCSV })
  }, [stringCSV])
  const resetData = useCallback(() => {
    setParsedData([])
    setTableData([])
  }, [])

  return {
    tableData,
    parsedData,
    onChangePagination,
    resetData
  }
}

type UploadTableProps<T> = {
  onChangePagination: PaginationProps['onChange']
  parsedData: T[]
  tableData: T[]
  columns: ColumnsType<T>
  rowKey: (record: T) => string
}

const UploadTable = <T extends object>({
  parsedData,
  tableData,
  columns,
  rowKey,
  onChangePagination
}: UploadTableProps<T>) => {
  const tableProps = {
    rowKey,
    scroll: {
      x: 'max-content'
    }
  }
  return (
    <>
      {parsedData.length > 0 && (
        <Pagination
          showQuickJumper
          defaultCurrent={1}
          onChange={onChangePagination}
          showSizeChanger={false}
          total={parsedData.length}
        />
      )}
      {tableData.length > 0 && (
        <Table
          {...tableProps}
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      )}
    </>
  )
}

type UseOnFinishInput<T, C extends Mixed> = {
  data: T[]
  formValuesType: C
  stringCSV: string
  uploadFunction: CsvUploadFunction
}

const useOnFinish = <T, C extends Mixed>({
  data,
  stringCSV,
  formValuesType,
  uploadFunction
}: UseOnFinishInput<T, C>) => {
  const showSuccessNotification = useSuccessNotificationShow()
  const checkValues = useCheckValuesWithErrorNotification()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard()

  return useCallback(() => {
    checkValues(formValuesType, data)

    uploadFunction(stringCSV).then(res => {
      copy(res.importId)
      showSuccessNotification(
        `Импорт успешно создан. Идентификатор импорта ${res.importId}.`
      )
    })
  }, [stringCSV, data])
}

type FormValues = {
  csv?: {
    file: RcFile
    fileList: RcFile[]
  }
}

const file5mb = 1_048_576

const DontClosePageWithLoading = ({ isLoading }: { isLoading: boolean }) => (
  <Title level={5}>
    Пожалуйста, не закрывайте вкладку во время отправки.{' '}
    {isLoading && <SpinnerText text="Происходит отправка данных" />}
  </Title>
)

const LargeFileWarning = () => (
  <Typography.Paragraph>
    Вы загружаете файл большого размер, вкладка временно может "зависнуть" на
    время валидации и отправки, среднее валидации и отправки в среднем
    составляет от 1 секунды до 3 минут.
  </Typography.Paragraph>
)

type UploadPageProps<T, C extends Mixed> = {
  formValuesType: C
  rowKey: (record: T) => string
  columns: ColumnsType<T>
  uploadFunction: CsvUploadFunction
  isLoading: boolean
}

// eslint-disable-next-line max-lines-per-function
export const UploadPage = <T extends object, C extends Mixed>({
  formValuesType,
  rowKey,
  columns,
  uploadFunction,
  isLoading
}: UploadPageProps<T, C>) => {
  const [form] = useForm<FormValues>()
  const [stringCSV, setStringSCV] = useState<string>('')
  const csvFile = useWatch('csv', form)
  const { tableData, parsedData, onChangePagination, resetData } =
    useTableData<T>(stringCSV)

  const onFinish = useOnFinish({
    data: parsedData,
    stringCSV,
    formValuesType,
    uploadFunction
  })

  const onReset = useCallback(() => {
    setStringSCV('')
    resetData()
  }, [])
  const uploadButtonProps = useMemo(
    () => ({
      onClick: () => form.submit(),
      children: 'Загрузить',
      hidden: !stringCSV,
      loading: isLoading
    }),
    [stringCSV, isLoading]
  )
  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <FormItem name="csv" valuePropName="fileList">
          <Uploader afterReadCSV={setStringSCV} onReset={onReset} />
        </FormItem>
        <CrudPanel uploadButtonProps={uploadButtonProps} />
        {csvFile?.file && <DontClosePageWithLoading isLoading={isLoading} />}
        {(csvFile?.file.size ?? 0) > file5mb && <LargeFileWarning />}
      </Form>
      <UploadTable
        columns={columns}
        onChangePagination={onChangePagination}
        parsedData={parsedData}
        rowKey={rowKey}
        tableData={tableData}
      />
    </>
  )
}
