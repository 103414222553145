import React from 'react'

import { Button, Dropdown } from 'antd'
import type { MenuProps } from 'antd'

import css from './index.module.scss'
import { showConfirm } from '../../atoms/confirmations'
import { useCertificateUpdateQuery } from '../../../api/certificates'
import { useSuccessNotificationShow } from '../../store/notifications'
import type {
  Certificate,
  useCertificatesQuery
} from '../../../api/certificates'

import { formatDisplayDate } from '../../lib/formatters'
import { pointsToShow } from '../../atoms/pointsConvert'
import { promocodeBusinessMap } from '../../atoms/mappers/promocodeBusiness'
import type { ColumnType } from 'antd/es/table'
import type { useLinkCertWithEntitiesModal } from '../../organisms/LinkCertWithEntitiesModal'

type ChangeIsActiveInput = {
  recordId: string
  recordName: string
}

type UseCertificatesChangeActiveStatusInput = {
  refetch: ReturnType<typeof useCertificatesQuery>['refetch']
  showLinkModal: (record: Certificate) => void
}

const useCompanyActivate = ({
  refetch
}: UseCertificatesChangeActiveStatusInput) => {
  const showSuccess = useSuccessNotificationShow()
  const { mutateAsync: update } = useCertificateUpdateQuery()

  return ({ recordId, recordName }: ChangeIsActiveInput) => {
    // eslint-disable-next-line max-len
    const confirmText = `Вы действительно хотите активировать сертификат: ${recordName}?`
    const successText = `${recordName} успешно активирован`

    showConfirm({
      title: 'Активация сертификата',
      content: confirmText,
      onOk: () => {
        update({ id: recordId, isActive: true }).then(() => {
          showSuccess(successText)
          refetch()
        })
      }
    })
  }
}

const useCompanyDeactivate = ({
  refetch
}: UseCertificatesChangeActiveStatusInput) => {
  const showSuccess = useSuccessNotificationShow()
  const { mutateAsync: update } = useCertificateUpdateQuery()

  return ({ recordId, recordName }: ChangeIsActiveInput) => {
    // eslint-disable-next-line max-len
    const confirmText = `Вы действительно хотите деактивировать сертификат: ${recordName}?`
    const successText = `${recordName} успешно деактивирован`

    showConfirm({
      title: 'Деактивация сертификата',
      content: confirmText,
      onOk: () => {
        update(
          { id: recordId, isActive: false }
          // eslint-disable-next-line sonarjs/no-identical-functions
        ).then(() => {
          showSuccess(successText)
          refetch()
        })
      }
    })
  }
}

const staticColumns: Array<ColumnType<Certificate>> = [
  {
    title: 'Имя',
    dataIndex: 'name'
  },
  {
    title: 'Баллы',
    dataIndex: 'amountPoints',
    render: (value: number) => pointsToShow(value)
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDatetime',
    render: (value: Date) => formatDisplayDate(value)
  },
  {
    title: 'Дата окончания',
    dataIndex: 'finishDatetime',
    render: (value: Date) => formatDisplayDate(value)
  },
  {
    title: 'Бизнес',
    dataIndex: 'business',
    render: (value: Certificate['business']) =>
      value ? promocodeBusinessMap[value] : '-'
  }
]

type UseLinkCertificateInput = {
  refetch: ReturnType<typeof useCertificatesQuery>['refetch']
}

type ChangeLinkInput = {
  id: string
  name: string
}

const useCompanyUnLinkCertificates = ({ refetch }: UseLinkCertificateInput) => {
  const showSuccess = useSuccessNotificationShow()
  const { mutateAsync: update } = useCertificateUpdateQuery()

  return ({ id, name }: ChangeLinkInput) => {
    const confirmTitleText = 'Перелинковка'
    const confirmContentText = `Отвязать компанию у сертификата ${name}?`
    const successText = `Компания у ${name} успешно отвязана`
    showConfirm({
      title: confirmTitleText,
      content: confirmContentText,
      onOkAsyncCb: () =>
        update({ id, companyId: null }).then(() => {
          showSuccess(successText)
          refetch()
        })
    })
  }
}

const useClientUnLinkCertificates = ({ refetch }: UseLinkCertificateInput) => {
  const showSuccess = useSuccessNotificationShow()
  const { mutateAsync: update } = useCertificateUpdateQuery()

  return ({ id, name }: ChangeLinkInput) => {
    const confirmTitleText = 'Перелинковка'
    const confirmContentText = `Отвязать клиента у сертификата ${name}?`
    const successText = `Клиент у ${name} успешно отвязан`
    showConfirm({
      title: confirmTitleText,
      content: confirmContentText,
      onOkAsyncCb: () =>
        update({ id, userId: null }).then(() => {
          showSuccess(successText)
          refetch()
        })
    })
  }
}

const useUnLinkCert = ({ refetch }: UseLinkCertificateInput) => {
  const companyUnLink = useCompanyUnLinkCertificates({
    refetch
  })
  const clientUnLink = useClientUnLinkCertificates({
    refetch
  })

  return {
    companyUnLink,
    clientUnLink
  }
}

const getItems = (disabledKeys: Record<string, boolean>): MenuProps['items'] =>
  [
    {
      label: 'Привязать',
      key: 'link'
    },
    {
      label: 'Отвязать компанию',
      key: 'unlink-company'
    },
    {
      label: 'Отвязать клиента',
      key: 'unlink-client'
    }
  ].map(item => {
    if (disabledKeys[item.key]) {
      return {
        ...item,
        disabled: true
      }
    }
    return item
  })

type useLinkColumnInput = {
  refetch: ReturnType<typeof useCertificatesQuery>['refetch']
  showLinkModal: ReturnType<typeof useLinkCertWithEntitiesModal>['show']
}

// eslint-disable-next-line max-lines-per-function
const useLinkColumn = ({ showLinkModal, refetch }: useLinkColumnInput) => {
  const { companyUnLink, clientUnLink } = useUnLinkCert({
    refetch
  })
  return {
    title: 'Клиент / Компания',
    dataIndex: 'user/company',
    render: (_: any, record: Certificate) => {
      const clientButtonText = record.user
        ? [record.user.leadId, record.user.name].filter(Boolean).join(' ')
        : '—'
      const companyButtonText = record.company ? record.company.name : '—'
      const onClick: MenuProps['onClick'] = ({ key }) => {
        if (key === 'link') {
          showLinkModal(record)
        }
        if (key === 'unlink-company') {
          companyUnLink(record)
        }
        if (key === 'unlink-client') {
          clientUnLink(record)
        }
      }
      return (
        <Dropdown
          menu={{
            items: getItems({
              'unlink-company': !record.company,
              'unlink-client': !record.user
            }),
            onClick
          }}
          trigger={['click']}
        >
          <Button className={css.actionButton} type="link">
            {[clientButtonText, '/', companyButtonText].join(' ')}
          </Button>
        </Dropdown>
      )
    }
  }
}

// eslint-disable-next-line max-lines-per-function
export const useColumns = (
  input: UseCertificatesChangeActiveStatusInput
): Array<ColumnType<Certificate>> => {
  const activate = useCompanyActivate(input)
  const deactivate = useCompanyDeactivate(input)
  const linkCol = useLinkColumn({
    showLinkModal: input.showLinkModal,
    refetch: input.refetch
  })

  return [
    ...staticColumns,
    linkCol,
    {
      title: '',
      dataIndex: 'actions-actions',
      render: (_, record: Certificate) => (
        <Button
          {...(record.isActive ? { danger: true } : {})}
          className={css.actionButton}
          onClick={() =>
            record.isActive
              ? deactivate({
                  recordId: record.id,
                  recordName: record.name
                })
              : activate({
                  recordId: record.id,
                  recordName: record.name
                })
          }
          type="link"
        >
          {record.isActive ? 'Деактивировать' : 'Активировать'}
        </Button>
      ),
      fixed: 'right',
      width: 150
    }
  ]
}
