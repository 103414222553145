import { apiClient } from '.'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { Rate } from './types/rate'

const RATES = 'RATES'

const rates = {
  get: () => apiClient.get<Rate[]>('/rates').then<Rate[]>(res => res.data)
}

export const useRatesQuery = () =>
  useQuery<Rate[]>([RATES, 'get'], () => rates.get())

type PsychologistsUploadQuery = { importId: string }

export type QueryParams = Record<string, string | number | boolean>

export type RatesUploadParams = {
  stringCSV: string
  params?: QueryParams
}

export const useRatesUploadQuery = () =>
  useMutation(({ stringCSV, params }: RatesUploadParams) =>
    apiClient
      .post<PsychologistsUploadQuery>('/rates/import', stringCSV, {
        headers: { 'content-type': 'text/csv' },
        params
      })
      .then(res => res.data)
  )
