import React from 'react'
import css from './index.module.scss'
import type { FC, PropsWithChildren, ReactNode } from 'react'

const WithRequiredStar: FC<PropsWithChildren> = ({ children }) => (
  <span className={css.star}>{children}</span>
)

export const renderWithRequiredStar = (component: ReactNode) => (
  <WithRequiredStar>{component}</WithRequiredStar>
)
