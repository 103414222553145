/* eslint-disable max-lines-per-function */
import React from 'react'
import css from './index.module.scss'
import { Button, Col, Form, Radio, Row, Select, Typography } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { staticSelectPropsWithFilters } from '../../atoms/Select'
import type {
  AsyncSelectManyField,
  AsyncSelectSearchField,
  SearchField,
  SelectSearchField
} from '.'

function getFieldCommomProps<T>(field: SearchField) {
  return field.type !== 'radioGroup'
    ? ({
        className: css.fullWidth,
        placeholder: field.placeholder,
        disabled: field.disabled
      } as T)
    : {}
}

function getSelectFieldCommomProps(
  field: SelectSearchField | AsyncSelectSearchField | AsyncSelectManyField
) {
  return {
    loading: field.loading,
    options: field.options,
    ...staticSelectPropsWithFilters
  }
}

export const SelectMany = ({
  fieldOptions,
  name,
  label
}: {
  fieldOptions: AsyncSelectManyField
  name: string
  label: string
}) => (
  <Form.List name={name}>
    {(fields, { add, remove }) => (
      <Col style={{ width: '100%' }}>
        <Typography.Text>{label}</Typography.Text>
        {fields.map((field, index) => (
          <Row key={field.key} style={{ width: '100%', gap: '1%' }}>
            {index !== 0 && (
              <Form.Item name={[index, 'operator']}>
                <Radio.Group>
                  {[
                    {
                      label: 'И',
                      value: 'and'
                    },
                    {
                      label: 'ИЛИ',
                      value: 'or'
                    }
                  ].map(({ label, value }, index) => (
                    <Radio.Button key={index} value={value}>
                      {label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              name={[index, 'value']}
              rules={[{ required: true }]}
              style={{ width: '53%' }}
            >
              <Select
                {...getSelectFieldCommomProps(fieldOptions)}
                {...getFieldCommomProps<AsyncSelectSearchField>(fieldOptions)}
              />
            </Form.Item>

            {fields.length > 0 ? (
              <Button
                className="dynamic-delete-button"
                icon={<MinusCircleOutlined />}
                onClick={() => remove(field.name)}
                style={{ width: '25%' }}
                type="default"
              >
                Удалить {label}
              </Button>
            ) : null}
          </Row>
        ))}
        <Form.Item>
          <Button
            onClick={() => add({ value: undefined, operator: 'and' })}
            style={{ width: '60%' }}
            type="dashed"
          >
            <PlusOutlined />
            Добавить {label}
          </Button>
        </Form.Item>
      </Col>
    )}
  </Form.List>
)
