import * as t from 'io-ts'

const isString = (input: unknown): input is string => typeof input === 'string'

const stringIsNumberAsString = (input: unknown): input is string =>
  isString(input) && !Number.isNaN(Number(input))

export const positiveNumber = new t.Type<string, string, unknown>(
  'positiveNumberFromString',
  isString,
  (input, context) =>
    stringIsNumberAsString(input) && Number(input) > 0
      ? t.success(input)
      : t.failure(input, context),
  t.identity
)

export const percent = new t.Type<string, string, unknown>(
  'persentFromString',
  isString,
  (input, context) =>
    stringIsNumberAsString(input) && Number(input) >= 0 && Number(input) <= 100
      ? t.success(input)
      : t.failure(input, context),
  t.identity
)

export const booleanStringAllTypes = t.union([
  t.literal('true'),
  t.literal('false'),
  t.literal('0'),
  t.literal('1')
])
