import { IMPORTS } from './imports'
import { TRANSACTIONS } from './transactions'
import { USERS } from './users'
import { apiClient } from '.'
import {
  certificateCreate,
  certificateTopup,
  certificateUpdate,
  certificatesDownload,
  certificatesGet
} from './generated/certificates'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  CertificateCreateBody,
  CertificateTopupBody,
  CertificateUpdateBody,
  CertificatesDownloadParams,
  CertificatesGet200DataItem,
  CertificatesGetParams
} from './generated/index.schemas'
import type { CertificatesGetResult } from './generated/certificates'
import type { UseQueryOptions } from '@tanstack/react-query'

const CERTIFICATES = 'CERTIFICATES'

export type CertificatesResult = CertificatesGetResult
export type Certificate = CertificatesGet200DataItem

export const useCertificatesQuery = (
  params: CertificatesGetParams,
  options?: UseQueryOptions<CertificatesResult>
) =>
  useQuery<CertificatesResult>(
    [CERTIFICATES, 'get'],
    () => certificatesGet(params),
    options
  )

type PromocodeUpdateInput = CertificateUpdateBody & { id: string }

export const useCertificateUpdateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [CERTIFICATES, 'patch'],
    ({ id, ...input }: PromocodeUpdateInput) => certificateUpdate(id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CERTIFICATES, 'patch'])
      }
    }
  )
}

export const useCertificatesDownloadQueryRefetchOnly = (
  params: CertificatesDownloadParams
) =>
  useQuery<string>(
    [CERTIFICATES, 'download'],
    () => certificatesDownload(params),
    {
      initialData: '',
      ...refetchOnlyParams
    }
  )

export const useCertificateCreateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [CERTIFICATES, 'create'],
    (body: CertificateCreateBody) => certificateCreate(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CERTIFICATES, 'get'])
      }
    }
  )
}

export const useCertificateTopup = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [CERTIFICATES, 'topup'],
    (body: CertificateTopupBody) => certificateTopup(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TRANSACTIONS])
        queryClient.invalidateQueries([USERS])
      }
    }
  )
}

type CertificatesUploadResult = { importId: string }

export const useCertificatesUploadQuery = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [IMPORTS, CERTIFICATES],
    (stringCSV: string) =>
      apiClient
        .post<CertificatesUploadResult>('/certificates/upload', stringCSV, {
          headers: { 'content-type': 'text/csv' }
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([IMPORTS, CERTIFICATES])
      }
    }
  )
}
