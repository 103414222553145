import {
  Screens2FA,
  useSetUserOtpTokenInfoStore,
  useUserSetLoadingStore,
  useUserSetScreenStore
} from './store'
import { auth, parseServerError } from '../../../api/login'
import { useCallback } from 'react'
import type { AuthErrorResponse } from '../../../api/login'
import type { FormInstance } from 'antd'
import type { LoginFormValues } from '.'

export const useOtpRequestOnFinish = (
  setFields: FormInstance<LoginFormValues>['setFields']
) => {
  const setLoading = useUserSetLoadingStore()
  const setOtpTokenInfo = useSetUserOtpTokenInfoStore()
  const setScreen = useUserSetScreenStore()
  return useCallback((values: LoginFormValues) => {
    setLoading(true)
    auth
      .requestOtp({
        email: values.email,
        password: values.password
      })
      .then(response => {
        if (response.status === 200) {
          setOtpTokenInfo({ ...response.data, email: values.email })
          setScreen(Screens2FA.EMAIL_SENT)
        }
      })
      .catch((error: AuthErrorResponse) => {
        setFields([
          {
            name: 'email',
            errors: ['']
          },
          {
            name: 'password',
            errors: [parseServerError(error)]
          }
        ])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
}
