import FormItem from 'antd/es/form/FormItem'
import React from 'react'
import css from './index.module.scss'
import { InputNumber } from 'antd'

const requiredProps = {
  rules: [{ required: true }]
}

export const AmountPointsField = () => (
  <FormItem
    {...requiredProps}
    required
    label="Количество баллов для сертификата"
    name="amountPoints"
  >
    <InputNumber<number>
      className={css.fullWidth}
      min={1}
      placeholder="Введите"
      step="1"
    />
  </FormItem>
)
