import React from 'react'
import { FormDataType } from './io-types'
import { UploadPage } from '../../organisms/Uploader'
import { staticColumns } from './columns'
import { usePsychologistsUploadQuery } from '../../../api/psychologists'
import type { PsychologistCSV } from './columns'

const rowKey = (row: PsychologistCSV) => row._id

const PsychologistsUploadPage = () => {
  const { mutateAsync, isLoading } = usePsychologistsUploadQuery()
  return (
    <UploadPage
      columns={staticColumns}
      formValuesType={FormDataType}
      isLoading={isLoading}
      rowKey={rowKey}
      uploadFunction={mutateAsync}
    />
  )
}

export default PsychologistsUploadPage
