import React, { useCallback } from 'react'
import type dayjs from 'dayjs'
import type { FC } from 'react'

import { AmountPointsField } from './fields'
import {
  FinishDatetimeField,
  IsActiveField,
  PromocodeNameField,
  StartDatetimeField
} from '../AddPromocodeModal/fields'
import { Form } from 'antd'
import { Loader } from '../../atoms/Loader'
import { Modal } from '../../molecules/Modal'
import { formatSendDate } from '../../lib/formatters'
import { useCertificateCreateQuery } from '../../../api/certificates'
import { useForm } from 'antd/es/form/Form'
import { useSuccessNotificationShow } from '../../store/notifications'
import type { FormInstance } from 'antd/es/form/Form'
import type { FormValues } from './index.types'

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: 'Поле ${label} обязательно для заполнения'
}

const useOnFinish = (form: FormInstance<FormValues>) => {
  const { mutateAsync, isLoading } = useCertificateCreateQuery()
  const showSuccessNotification = useSuccessNotificationShow()

  return {
    isLoading,
    onFinish: useCallback((values: FormValues) => {
      mutateAsync({
        name: values.name,
        isActive: values.isActive,
        amountPoints: values.amountPoints * 100,
        startDatetime: values.startDatetime
          ? formatSendDate(values.startDatetime as dayjs.Dayjs)
          : undefined,
        finishDatetime: values.finishDatetime
          ? formatSendDate(values.finishDatetime as dayjs.Dayjs)
          : undefined
      }).then(() => {
        form.resetFields()
        showSuccessNotification(`Сертификат ${values.name} успешно создан`)
      })
    }, [])
  }
}

type Props = { onCancel: () => void }

const staticFormProps = {
  layout: 'vertical',
  initialValues: {
    isActive: true
  }
} as const

export const AddCertificateModal: FC<Props> = ({ onCancel }) => {
  const [form] = useForm<FormValues>()
  const { onFinish, isLoading } = useOnFinish(form)
  return (
    <Modal
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{
        onClick: () => form.submit(),
        disabled: isLoading
      }}
      onCancel={onCancel}
      title="Создание сертификата"
    >
      <Form
        disabled={isLoading}
        form={form}
        {...staticFormProps}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <PromocodeNameField />
        <AmountPointsField />
        <StartDatetimeField />
        <FinishDatetimeField />
        <IsActiveField />
        {isLoading && <Loader />}
      </Form>
    </Modal>
  )
}
