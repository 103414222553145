import { apiClient } from '.'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  CollectionsForPsychologistProfile,
  PsychologistProfileInfo,
  PsychologistProfileUpdateInput,
  User,
  UserPsychologist
} from './types/user'
import type { MaybeNull } from '../app/atoms/ts/generics'
import type { UseQueryOptions } from '@tanstack/react-query'

export const USERS = 'USERS'

type UserPatchInput = {
  company?: MaybeNull<string>
}

export enum UserRoleId {
  CLIENT = 'CLIENT',
  PSYCHOLOGIST = 'PSYCHOLOGIST'
}

export type GetUsersParams = {
  idEq?: string
  leadIdEq?: string
  phone?: number
  email?: string
  companyEq?: string
  psychologistEq?: string
  roleEq?: UserRoleId
  skip?: number
  limit?: number
}

type GetUsersResult = {
  data: User[]
  total: number
}

type UserGetPsychologistProfileParams = {
  id?: string
}
type PublishUserProfileInput = {
  id?: string
  publishedAt?: string
}

const users = {
  getUsers: (params: GetUsersParams) =>
    apiClient.get<GetUsersResult>('/users', { params }).then(res => res.data),
  getPsychologists: () =>
    apiClient
      .get<UserPsychologist[]>('/users/psychologists_names')
      .then(res => res.data),
  patch: (id: string, update: UserPatchInput) =>
    apiClient.patch(`/users/${id}`, update),
  getPsychologistProfile: (params: UserGetPsychologistProfileParams) =>
    apiClient
      .get<PsychologistProfileInfo>(`/user/psychologist/${params.id}`)
      .then(res => <PsychologistProfileInfo>res.data),
  getCollectionsForPsychologistProfile: () =>
    apiClient
      .get<CollectionsForPsychologistProfile>('/user/psychologist/collections')
      .then(res => <CollectionsForPsychologistProfile>res.data),
  updateUserProfile: (input: PsychologistProfileUpdateInput) => {
    const { id, ...body } = input
    return apiClient.patch(`/user/psychologist/patch/${id}`, body)
  },
  createPsychologistProfile: (input: PsychologistProfileUpdateInput) =>
    apiClient.post<{ id: string }>('/user/psychologist/create', input),
  publishPsychologistProfile: (input: PublishUserProfileInput) =>
    apiClient.patch(`/user/psychologist/publish/${input.id}`, input)
}

export const useUsersPsychologistsQuery = (
  options?: UseQueryOptions<UserPsychologist[]>
) =>
  useQuery<UserPsychologist[]>(
    [USERS, 'getPscyhologists'],
    () => users.getPsychologists(),
    options
  )

export const useUsersQuery = (
  params: GetUsersParams,
  options?: UseQueryOptions<GetUsersResult>
) =>
  useQuery<GetUsersResult>(
    [USERS, 'getUsers', params],
    () => users.getUsers(params),
    options
  )

export const useUserUpdateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [USERS, 'update'],
    ({ id, ...input }: UserPatchInput & { id: string }) =>
      users.patch(id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS, 'get'])
      }
    }
  )
}

export const useGetPsychologistProfileQuery = (
  params: UserGetPsychologistProfileParams,
  options?: UseQueryOptions<PsychologistProfileInfo>
) =>
  useQuery<PsychologistProfileInfo>(
    [USERS, 'getPsychologistProfile', params],
    () => users.getPsychologistProfile(params),
    options
  )

export const useGetPsychologistProfileQueryRefetchOnly = (
  params: UserGetPsychologistProfileParams
) => useGetPsychologistProfileQuery(params, refetchOnlyParams)

export const useGetCollectionsForProfileQuery = (
  options?: UseQueryOptions<CollectionsForPsychologistProfile>
) =>
  useQuery<CollectionsForPsychologistProfile>(
    [USERS, 'getCollectionsForPsychologistProfile'],
    () => users.getCollectionsForPsychologistProfile(),
    options
  )

export const usePatchPsychologistProfileQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [USERS, 'updateUserProfile'],
    (input: PsychologistProfileUpdateInput) => users.updateUserProfile(input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS, 'getPsychologistProfile'])
      }
    }
  )
}

export const useCreatePsychologistProfileQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [USERS, 'createPsychologistProfile'],
    (input: PsychologistProfileUpdateInput) =>
      users.createPsychologistProfile(input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS, 'getPsychologistProfile'])
        queryClient.invalidateQueries([USERS, 'getPsychologists'])
      }
    }
  )
}

export const usePublishPsychologistProfileQuery = () =>
  useMutation(
    [USERS, 'publishPsychologistProfile'],
    (input: PublishUserProfileInput) => users.publishPsychologistProfile(input)
  )
