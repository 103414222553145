import { IMPORTS } from './imports'
import { apiClient, baseURL } from '.'
import {
  psychologistsGet,
  psychologistsSearchGet
} from './generated/psychologists'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  PsychologistsGet200DataItem,
  PsychologistsGetParams,
  PsychologistsSearchGetParams
} from './generated/index.schemas'
import type {
  PsychologistsGetResult,
  PsychologistsSearchGetResult
} from './generated/psychologists'
import type { UseQueryOptions } from '@tanstack/react-query'

export const PSYCHOLOGIST_AVATAR_UPLOAD_URL = [
  baseURL,
  '/psychologists/avatar_upload'
].join('')

const PSYCHOLOGISTS = 'PSYCHOLOGISTS'

export type Psychologist = PsychologistsGet200DataItem

export const usePsychologistsQuery = (
  params: PsychologistsGetParams,
  options?: UseQueryOptions<PsychologistsGetResult>
) =>
  useQuery<PsychologistsGetResult>(
    [PSYCHOLOGISTS, 'get'],
    () => psychologistsGet(params),
    options
  )

export const usePsychologistsSearchQuery = (
  params: PsychologistsSearchGetParams,
  options?: UseQueryOptions<PsychologistsSearchGetResult>
) =>
  useQuery<PsychologistsSearchGetResult>(
    [PSYCHOLOGISTS, 'get'],
    () => psychologistsSearchGet(params),
    options
  )

export const usePsychologistsSearchQueryRefetchOnly = (
  params: PsychologistsSearchGetParams
): ReturnType<typeof usePsychologistsSearchQuery> =>
  usePsychologistsSearchQuery(params, refetchOnlyParams)

type PsychologistsUploadQuery = { importId: string }

export const usePsychologistsUploadQuery = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [IMPORTS, PSYCHOLOGISTS],
    (stringCSV: string) =>
      apiClient
        .post<PsychologistsUploadQuery>('/psychologists/upload', stringCSV, {
          headers: { 'content-type': 'text/csv' }
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([IMPORTS, PSYCHOLOGISTS])
      }
    }
  )
}
