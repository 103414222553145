import dayjs from 'dayjs'
import { CurrencySigns } from '../atoms/mappers/transactionCurrency'
import { useMemo } from 'react'
import type { MaybeNull } from '../atoms/ts/generics'

export function formatDisplayBoolean(value: boolean) {
  return value ? 'Да' : 'Нет'
}
export function formatDisplayDate(value?: string | Date) {
  return value ? dayjs(value).format('D MMMM, YYYY') : undefined
}
export function formatDisplayDateWithTime(value?: string | Date) {
  return value ? dayjs(value).format('D MMMM, YYYY, HH:mm') : undefined
}
export function formatDisplayBooleanUndefined(
  value: boolean | undefined,
  emptyText = 'Не установлено'
) {
  return value === undefined ? emptyText : formatDisplayBoolean(value)
}

export function formatDisplayNullableValue<T = MaybeNull<string | number>>(
  value?: T,
  emptyText = '—'
) {
  return value ? value : emptyText
}

export const formatDisplayByMapper = <T>(
  mapper: T,
  key?: keyof T,
  emptyText?: '—'
) => {
  if (!key) {
    return emptyText
  }
  return mapper[key]
}

export const formatPoints = (price: number) =>
  new Intl.NumberFormat('ru-Ru').format(price / 100).replace(' ', '\u00A0')

export const formatPrice = (price: number, currency = CurrencySigns.RUB) =>
  `${formatPoints(price)} ${currency}`

enum SendDateModificator {
  START_OF_DAY = 'startOfDay',
  END_OF_DAY = 'endOfDay'
}

export const formatSendDate = (
  date?: dayjs.Dayjs,
  modificator?: SendDateModificator
) => {
  if (modificator === SendDateModificator.START_OF_DAY) {
    return dayjs(date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
  }
  if (modificator === SendDateModificator.END_OF_DAY) {
    return dayjs(date).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
  }
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
}

const formatSendDateStartOfDay = (date?: dayjs.Dayjs) =>
  formatSendDate(date, SendDateModificator.START_OF_DAY)
const formatSendDateEndOfDay = (date?: dayjs.Dayjs) =>
  formatSendDate(date, SendDateModificator.END_OF_DAY)

export const useGetDateRangeForSend = (range?: dayjs.Dayjs[]) =>
  useMemo(
    () =>
      Array.isArray(range)
        ? [formatSendDateStartOfDay(range[0]), formatSendDateEndOfDay(range[1])]
        : [undefined, undefined],
    [range]
  )
