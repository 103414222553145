import React from 'react'
import { FormDataType } from './io-types'
import { UploadPage } from '../../organisms/Uploader'
import { staticColumns } from './columns'
import { useBadgesUploadQuery } from '../../../api/badges'

const rowKey = (row: { id: string }) => row.id

const BadgesUploadPage = () => {
  const { mutateAsync, isLoading } = useBadgesUploadQuery()
  return (
    <UploadPage
      columns={staticColumns}
      formValuesType={FormDataType}
      isLoading={isLoading}
      rowKey={rowKey}
      uploadFunction={mutateAsync}
    />
  )
}

export default BadgesUploadPage
